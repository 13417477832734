import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "USER_LOGIN",
  Logout: "[Logout] Action",
  SetToken: "SET_TOKEN",
  SetUser: "SET_USER",
  SetMenu: "SET_MENU",
};

const initialAuthState = {
  menu: null,
  user: null,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v713-demo1-auth", whitelist: ["user", "authToken","menu"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { headers } = action.payload.userData;
        return { authToken: headers.token, user: undefined };
      }

      case actionTypes.SetToken: {
        const { token } = action.payload;
        return { ...state, authToken: token };
      }

      case actionTypes.SetMenu: {
        const { resMenu } = action.payload 
        return { ...state, menu: resMenu };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (userData) => {
    return ({ type: actionTypes.Login, payload: { userData } })
  },
  logout: () => ({ type: actionTypes.Logout }),
  setToken: ({ token }) => ({ type: actionTypes.SetToken, payload: { token } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  SetMenu: (resMenu) => ({ type: actionTypes.SetMenu, payload: { resMenu } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga({ payload }) {
    const { userData } = payload;
    yield put(actions.setToken(userData.headers));
    // yield put(actions.setUser({...userData.data?.data?.adminSignIn}));
    yield put(actions.setUser({...userData.data?.data?.adminSignIn,selectedClientId:userData.data?.data?.adminSignIn.clientId}));
  });
}
