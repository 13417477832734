import moment from 'moment';


// today-date
let today_date = moment().format('YYYY-MM-DD');
// max and min no of years.
let maxYear = moment(today_date).subtract(16, 'Y').format('YYYY');
let minYear = moment(today_date).subtract(100, 'Y').format('YYYY');
// date 16 years
export const _birthDate = moment(today_date).subtract(16, 'Y').format('YYYY-MM-DD');
// max and min no of dates
export const maxDate = moment(`${maxYear}-12-31`).format('YYYY-MM-DD');
export const minDate = moment(`${minYear}-01-01`).format('YYYY-MM-DD');