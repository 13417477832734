import React from 'react'


const ContentHeading = ({title})=>{
    return(
      <div className="row">
          <div className="col-12">
            <h1>{title}</h1>
          </div>  
        </div>
    )
  }

export default ContentHeading;