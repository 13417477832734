export const Query_AllAdminUsers = `query allAdminUsers ($adminUser: UserWithPagination!) {
  allAdminUsers(adminUser: $adminUser) { 
    totalRecords
    adminUsers {
      id
      userName
      firstName
      lastName
      email 
      adminRoleId
      adminRoleName
      phone  
      birthDate
      address {
        addressLine1 
        addressLine2
        city 
        state 
        zipCode
      }
      isAutoCreated
      clientId
      isActive
    }
  }
}`
export const Operation_AllAdminUsers = `allAdminUsers`;

// Site Queries
export const Query_AllSite = `query allSite ($site: SiteWithPagination!) {
  allSite(site: $site) {  
    totalRecords
    Site{
      id
      name
      code
      domain
      siteId
      phoneNumber
      email
      enablement{
        siteEnableName
        siteEnableId
        isEnable
      } 
      isActive
      address{
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
      updatedAt
      
    }
  }
}`
export const Operation_AllSite = `allSite`;


// Search Site Queries

export const Query_SearchSite = `query searchSite ($site: SiteSearchInput!) {
    searchSite(site: $site)  {
      totalRecords
      Site{
        id
        name
        code
        domain
        siteId
        phoneNumber
        email
        enablement{
          siteEnableName
          siteEnableId
          isEnable
        } 
        isActive
        address{
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        updatedAt
      }
      
    }
}`
export const Operation_SearchSite = `searchSite`;

export const Query_SiteYearlyReport = `query yearlyReport($report: SiteReportYear!) {
    yearlyReport(report: $report) {
      data
    }
}`;
export const Operation_SiteYearlyReport = `yearlyReport`;

export const Query_SiteByState = `query getSiteByState ($site: SiteSearchInput!) {
    getSiteByState(site: $site)  {
      totalRecords
      Site{
        id
        name
        code
        domain
        siteId
        phoneNumber
        email
        enablement{
          siteEnableName
          siteEnableId
          isEnable
        } 
        isActive
        address{
          addressLine1
          addressLine2
          city
          state
          zipCode
          latitude
          longitude
        }
        updatedAt
      }
    }
}`;
export const Operation_SiteByState = `getSiteByState`;
// change site status mutation
export const Mutation_changeSiteStatus = `mutation changeSiteStatus($site: SiteId!) {
  changeSiteStatus (site: $site)  {
     result
     message
  }
}`;
export const Operation_changeSiteStatus = `changeSiteStatus`;
// UPDATE site mutation
export const Mutation_UpdateSite = `mutation updateSite($site: SiteInput!) {
  updateSite(site: $site) {
    result
    message
  }
}`;
export const Operation_UpdateSite = `updateSite`;
// Add Site mutation
export const Mutation_CreateSite = `mutation createSite($site: SiteInput!) {
  createSite(site: $site) {
    id
		name
    code
    domain
    siteId
    phoneNumber
    email
    enablement{
      siteEnableId
      isEnable
    }
    isActive
		address{
			addressLine1 
			addressLine2 
			city 
			state
			zipCode
		}
  }
}`;
export const Operation_CreateSite = `createSite`;
// GET siteById
export const Query_SiteById = `query siteById($site: SiteId!) {
  siteById(site: $site) {
    id
		name
    code
    domain
    siteId
    phoneNumber
    email
    enablement {
      siteEnableName
      siteEnableId
      isEnable
    }
    isActive
    address{
      addressLine1
      addressLine2
      city
      state
      zipCode
    }
    updatedAt
  }
}`;
export const Operation_SiteById = `siteById`;


// Site Enablement Quries
export const Query_AllSiteEnablements = `query allSiteEnablements {
    allSiteEnablements {
      id
      name
      ranking
      isOnlyForParentAdmin
    }
  } `
export const Operation_AllSiteEnablements = `allSiteEnablements`;

// siteEnablement for admin role Quries
export const Query_SiteEnablement = `query siteEnablement {
  siteEnablement {
      siteEnableName
      siteEnableId
      isEnable
  }
}`
export const Operation_SiteEnablement = `siteEnablement`;

// Donor Queries
export const Query_AllDonors = `query allDonorsWithPagination ($donor: DonorWithPagination!) {
    allDonorsWithPagination(donor: $donor) {
      totalCount
      donors{
        donorIdentifier {
          id
          personId
          donorId
        }
      firstName
      lastName
      gender
      birthDate
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
      emailAddress
      homePhone
      nextEligible
      nextEligibleWB
      nextEligiblePL
      bloodType
      lastDonationDate
      phoneNumber
      }
    }
  }`
export const Operation_AllDonors = `allDonorsWithPagination`;

export const Query_TotalDonors = `query totalDonors {
  totalDonors
}`
export const Operation_TotalDonors = `totalDonors`;


// Donors By State
export const Query_DonorsByState_Count = `query donorsByState ($state: String!) {
    donorsByState(state: $state) {
      totalCount
    }
  }`;
export const Operation_DonorsByState_Count = `donorsByState`;

export const Query_YearlyDonorReport = `query annualDonorReport($year: String!) {
    annualDonorReport(year: $year) {
      data
    }
  }`;
export const Operation_YearlyDonorReport = `annualDonorReport`;

// User
export const Mutation_AdminSignIn = `mutation adminSignIn($user: SigninUserInput!) {
    adminSignIn(user: $user) {
      id
      donorId
      personId
      email
      role 
      clientId
      adminRole_Id
      adminRole_Name
      name
      firstName
      lastName
      phone
      birthDate
      gender
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
      files {
        id
        type
        path
      }
      devices {
        id
        platform
        operatingSystem
        source
        version
      }
      community {
        id
        name
        title
      }
    }
  }`;
export const Operation_AdminSignIn = `adminSignIn`;

export const Query_RoleById = `query adminRoleById ($adminRole: AdminRoleId!) {
    adminRoleById(adminRole: $adminRole)  { 
       id
      roleName
      detail 
      clientId
      isAutoCreated
      enablement{
        Id
        Name  
        Url
        Read
        Write
        Delete
      }
    }
  }`;
export const Operation_RoleById = `adminRoleById`;

export const Query_UserRoleById = `query userAdminRoleById ($adminRole: AdminRoleId!) {
    userAdminRoleById(adminRole: $adminRole)  { 
      id
      roleName
      detail 
      clientId
      isAutoCreated
      enablement{
        Id
        Name  
        Url
        Read
        Write
        Delete
        Children {
          name
          url
          ranking
        }
      }
    }
  }`;
export const Operation_UserRoleById = `userAdminRoleById`;

export const Query_SearchAdminUser = `query adminUserByName ($adminUser: AdminUserSearchInput!) {
  adminUserByName(adminUser: $adminUser)  {  
     totalRecords
    adminUsers {
    id
      userName
      firstName
      lastName
      email 
      adminRoleId
      adminRoleName
      phone  
      birthDate
      address {
        addressLine1 
        addressLine2
        city 
        state 
        zipCode
      }
      isAutoCreated
      clientId
      isActive
    }
  }
}`;
export const Operation_SearchAdminUser = `adminUserByName`;

// allAdminRoles
export const Query_AllAdminRoles = `query allAdminRoles ($adminRole: AdminRoleWithPagination!) {
  allAdminRoles(adminRole: $adminRole) { 
    totalRecords
    adminRoles {
      id
      roleName
      detail
      clientId
      isAutoCreated
      enablement {
        Id
        Name 
        Read
        Write
        Delete
      }
    }
  }
}`
export const Operation_AllAdminRoles = `allAdminRoles`;

export const Query_SearchAdminRoles = `query searchAdminRoles ($adminRole: AdminRoleSearchInput!) {
  searchAdminRoles(adminRole: $adminRole)  {
    totalRecords
    adminRoles {
      id
      roleName
      detail
      clientId
      enablement {
        Id
        Name
        Read
        Write
        Delete
       }
    }
  }
}
 `
export const Operation_SearchAdminRoles = `searchAdminRoles`;

// searchAdminRoles
export const Query_CreateSuperUser = `mutation createSuperUser($user: SignupUserInput!){
  createSuperUser(user: $user) {
    id
		donorId
		personId
		email
		role 
    adminRole_Id
		name
		firstName
		lastName
    phone
    gender
		address {
      addressLine1
      addressLine2
      city
      state
      zipCode
		}
		birthDate
		drmRegistrationRequested
		drmRegistrationCompleted
		provider
		devices {
			id
			platform
			operatingSystem
			version
			source
		}
    clientId
  }
}`
export const Operation_CreateSuperUser = `createSuperUser`;

export const Query_AdminUserById = `query adminUserById ($adminUser: AdminUserId!) {
  adminUserById(adminUser: $adminUser)  {  
      id
      userName
      firstName
      lastName
      email 
      adminRoleId
      adminRoleName
      phone
      gender
      birthDate
      address {
        addressLine1 
        addressLine2
        city 
        state 
        zipCode
      }
      isAutoCreated
      clientId
      isActive
    
  }
}
  `;
export const Operation_AdminUserById = `adminUserById`;

export const Mutation_UpdateAdminUser = `mutation updateAdminUser($user: UpdateAdminInput!){
  updateAdminUser(user: $user) {
      id 
      firstName 
      lastName 
      userName 
      email 
      phone 
      adminRoleId  
      birthDate 
      address{
        addressLine1 
        addressLine2 
        city addressLine1
        state  
        zipCode 
      }
      clientId 
      isActive 
    }
  }`;
export const Operation_UpdateAdminUser = 'updateAdminUser';

// CREATE ADMIN ROLES
export const Mutation_CreateAdminRole = `mutation createAdminRole($adminRole: AdminRoleInput!) {
  createAdminRole(adminRole: $adminRole) {
    id
		roleName
		detail 
    clientId
    enablement{
      Id
      Name  
      Read
      Write
      Delete
    }
  }
}`;
export const Operation_CreateAdminRole = 'createAdminRole';

// update admin roles
export const Mutation_UpdateAdminRole = `mutation updateAdminRole($adminRole: AdminRoleInput!) {
  updateAdminRole(adminRole: $adminRole) {
    result
    message
  }
}`;
export const Operation_UpdateAdminRole = 'updateAdminRole';
// DELETE ADMIN ROLES
export const Mutation_DeleteAdminRole = `mutation deleteAdminRole($adminRole: AdminRoleId!) {
  deleteAdminRole (adminRole: $adminRole)  {
     result
     message
  }
}`;
export const Operation_DeleteAdminRole = 'deleteAdminRole';

export const Query_AllAdminRolesWithOutRoleValidation = `query allAdminRolesWithOutRoleValidation ($adminRole: AdminRoleWithPagination!) {
  allAdminRolesWithOutRoleValidation(adminRole: $adminRole) { 
    totalRecords
    adminRoles {
      id
      roleName
      detail
      clientId
      isAutoCreated
      enablement {
        Id
        Name 
        Read
        Write
        Delete
      }
    }
  }
}`
export const Operation_AllAdminRolesWithOutRoleValidation = `allAdminRolesWithOutRoleValidation`;

export const Query_DonorSearch = `query donorsSearchSortWithPagination ($donor: DonorSearchAndSort!) {
  donorsSearchSortWithPagination(donor: $donor) {
    totalCount
    donors{
      donorIdentifier {
      id
      personId
      donorId
    }
    firstName
    lastName
    gender
    birthDate
    address {
      addressLine1
      addressLine2
      city
      state
      zipCode
    }
    emailAddress
    homePhone
    nextEligible
    nextEligibleWB
    nextEligiblePL
    bloodType
    lastDonationDate
    phoneNumber
    }
  }
}`
export const Operation_DonorSearch = `donorsSearchSortWithPagination`;

export const Query_AllAds = `query getAllAds($ads: PaginationAds!)  {
  getAllAds(ads: $ads)  {
    totalRecords
    Ads{
      id
    name
    redirectUrl
    sortOrder
    heading
    text  
    cmsType
    clientId 
    file {
      path
      type
    }
    isActive
    } 
  }
}`
export const Operation_AllAds = `getAllAds`;

export const Query_SearchAds = `query searchAds($ads: SearchAds!)  {
  searchAds(ads: $ads)  {
    totalRecords
    Ads{
      id
    name
    redirectUrl
    sortOrder
    heading
    text  
    cmsType
    clientId 
    file {
      path
      type
    }
    isActive
    }
    
  }
}`
export const Operation_SearchAds = `searchAds`;

export const Query_AdsById = `query cmsAdsById($id: String!) {
  cmsAdsById(id: $id) { 
    id
    name
    redirectUrl
    cmsType
    clientId 
    sortOrder
    heading
    text
    file {
      path
      type
    }
    isActive
  }
}`
export const Operation_AdsById = `cmsAdsById`;

export const Query_AdsDeleteById = `mutation deleteCMSAds($id: String!) {
  deleteCMSAds (id: $id)  {
     result
     message
  }
}`
export const Operation_AdsDeleteById = `deleteCMSAds`;

export const Query_DonorById = `query donorDetailsByIdForAdmin($id: Int!) {
  donorDetailsByIdForAdmin(id: $id) {
		firstName
		lastName
		gender
		birthDate			
	  donorIdentifier {
			id
			personNumber
			donorId	
		}
    address {
      addressLine1
      addressLine2
      city
      state
      zipCode
    }		
		emailAddress
		homePhone
    nextEligible
    nextEligibleWB
    nextEligiblePL
  }
}`
export const Operation_DonorById = `donorDetailsByIdForAdmin`;


// uploadAdsFile
export const Mutation_UploadAdsFile = `mutation uploadAdsFile($cmsAds: CMSAdsInput!){
  uploadAdsFile(cmsAds: $cmsAds) {
		id
    name
    redirectUrl
    sortOrder
    heading
    text
    cmsType
    clientId
    file {
      path
      type
    }
    isActive
  }
}`
export const Operation_UploadAdsFile = `uploadAdsFile`;

// updateAdsFile
export const Mutation_UpdateAdsFile = `mutation updateAds($cmsAds: CMSAdsInput!){
  updateAds(cmsAds: $cmsAds) {
		id
    name
    redirectUrl
    sortOrder
    cmsType
    clientId
    heading
    text
    file {
      path
      type
    }
    isActive
  }
}`
export const Operation_UpdateAdsFile = `updateAds`;

// get JWT
export const Mutation_GetJWTByEmail = `mutation getJWT($email: String!) {
  getJWT(email: $email)  
}`
export const Operation_GetJWTByEmail = `getJWT`;

export const Query_UnconfirmedAppointments = `query allUnconfirmedDonorAppointments {
  allUnconfirmedDonorAppointments {
    futureAppointments {
      appointment {
        id
        donationSiteId
        startTime
        drmRequestInitiated
        drmResponseReceived
        canceled
        appointmentId
      }
      drive {
        id
        type
        driveDate
        location {
          id
          name
          type
        }
      } 
    }
  }
}`
export const Operation_UnconfirmedAppointments = `allUnconfirmedDonorAppointments`;

export const Query_ConfirmedAppointments = `query allConfirmedDonorAppointments {
	allConfirmedDonorAppointments {
		futureAppointments {
      appointment {
        id
        donationSiteId
        startTime
        drmRequestInitiated
        drmResponseReceived
        canceled
        appointmentId
      }
			appointmentRequestId
			appointmentId			
			startTimeOnlyUTC
			nextDay
			startTimeUTC
			drive {
				id
				type
				driveDate
				location {
					id
					name
					type
				}				
			}	
			donated			
		}			
		pastAppointments {
      appointment {
        id
        donationSiteId
        startTime
        drmRequestInitiated
        drmResponseReceived
        canceled
        appointmentId
      }
			appointmentRequestId
			appointmentId			
			startTimeOnlyUTC
			nextDay
			startTimeUTC
			drive {
				id
				type
				driveDate
				location {
					id
					name
					type
				}				
			}	
			donated			
		}	
	}
}`
export const Operation_ConfirmedAppointments = `allConfirmedDonorAppointments`;

export const Query_EmailTemplateAll = `query allEmailTemplate ($emailTemplate: EmailTemplateWithPagination!) {
  allEmailTemplate(emailTemplate: $emailTemplate) {  
    totalRecords
    EmailTemplate{
      id
      name
      subject
      template
      clientId
      updatedAt
    }
    }
}`
export const Operation_EmailTemplateAll = `allEmailTemplate`;

export const Query_EmailTemplateSearch = `query searchEmailTemplate ($emailTemplate: EmailTemplateSearchInput!) {
  searchEmailTemplate(emailTemplate: $emailTemplate)  {
    totalRecords
    EmailTemplate{
      id
      name
      subject
      template
      clientId
      updatedAt
    }   
  }
}`
export const Operation_EmailTemplateSearch = `searchEmailTemplate`;

export const Mutation_EmailTemplateDelete = `mutation deleteEmailTemplate($emailTemplate: EmailTemplateId!) {
  deleteEmailTemplate (emailTemplate: $emailTemplate)  {
     result
     message
  }
}`
export const Operation_EmailTemplateDelete = `deleteEmailTemplate`;

export const Query_EmailTemplateById = `query EmailTemplateById($emailTemplate: EmailTemplateId!) {
  EmailTemplateById(emailTemplate: $emailTemplate) {
    id
		name
    subject
    template
    updatedAt
  }
}`
export const Operation_EmailTemplateById = `EmailTemplateById`

export const Mutation_EmailTemplateUpdate = `mutation updateEmailTemplate($emailTemplate: EmailTemplateInput!) {
  updateEmailTemplate(emailTemplate: $emailTemplate) {
    result
    message
  }
}`
export const Operation_EmailTemplateUpdate = `updateEmailTemplate`;

export const Mutation_EmailTemplateAdd = `mutation createEmailTemplate($emailTemplate: EmailTemplateInput!) {
  createEmailTemplate(emailTemplate: $emailTemplate) {
    id
		name
    subject
    template
    clientId
    updatedAt
  }
}`
export const Operation_EmailTemplateAdd = `createEmailTemplate`;


// SignUp

export const Mutation_AddDonor = `mutation signUp($user: SignupUserInput!){
  signUp(user: $user) {
    id
		donorId
		personId
		email
		role
		name
		firstName
		lastName
    phone
    gender
		address {
      addressLine1
      addressLine2
      city
      state
      zipCode
		}
		birthDate
		drmRegistrationRequested
		drmRegistrationCompleted
		provider
		devices {
			id
			platform
			operatingSystem
			version
			source
		}
    clientId
  }
}`
export const Operation_AddDonor = `signUp`;

export const Query_AllDriveLocations = `query allDriveLocations($drive: DriveSearchInput!)
{
  allDriveLocations(drive: $drive) {
    id
    address {
      address
      address2
      city
      state
      zipCode
    }
    phoneNumber
    location {
      id
      name
      type
    }
    operatingHours {
      morningStart {
        appointmentTime
        nextDay
        startTimeUTC
      }
      morningEnd {
        appointmentTime
        nextDay
        startTimeUTC
      }
      afternoonStart {
        appointmentTime
        nextDay
        startTimeUTC
      }           
      afternoonEnd {
        appointmentTime
        nextDay
        startTimeUTC
      }           
    }
    specialInstructions
    distance
    geocode {
      lat
      lng
    }
  }
}`
export const Operation_AllDriveLocations = `allDriveLocations`;

export const Query_AllAvailableAppointments = `query allAvailableAppointments($appointment: AvailableAppointmentsRequest!)
{
    allAvailableAppointments(appointment: $appointment) {
         driveId
        locationId
        allowSameday
        slots {
            morning
            afternoon
            evening
        }
        window {
            startTimeOnlyUTC
            startTimeUTC
            nextDay    
            slots
        }
    }
}`
export const Operation_AllAvailableAppointments = `allAvailableAppointments`;

export const Query_ModifyAppointmentByAdmin = `mutation modifyAppointmentByAdmin($appointment: AppointmentModificationRequestFromAdmin!) {
  modifyAppointmentByAdmin(appointment: $appointment)
    {
      id
      donationSiteId
      startTime
      drmRequestInitiated
    }
  }`
export const Operation_ModifyAppointmentByAdmin = `modifyAppointmentByAdmin`;

export const Query_AddAppointmentByAdmin = `mutation addAppointmentByAdmin($appointment: AppointmentBookingRequestFromAdmin!) {
  addAppointmentByAdmin(appointment: $appointment)
    {
      id
      donationSiteId
      startTime
      drmRequestInitiated
    }
  }`
export const Operation_AddAppointmentByAdmin = `addAppointmentByAdmin`;

export const Query_CancelAppointmentByAdmin = `mutation cancelAppointmentByAdmin($appointment: AppointmentCancellationRequestFromAdmin!) {
	cancelAppointmentByAdmin(appointment: $appointment)
}`
export const Operation_CancelAppointmentByAdmin = `cancelAppointmentByAdmin`;

export const Query_CreateOTPForResetPassword
  = `mutation createOTPForResetPasswordForAdminUser($emailOTP: EmailOTPInput!) {
  createOTPForResetPasswordForAdminUser(emailOTP: $emailOTP) {
    id
    clientId
    email
    OTP
    expiryDate
  }
}`
export const Operation_CreateOTPForResetPassword = `createOTPForResetPasswordForAdminUser`;


export const Query_ResetPasswordByOTP = `query resetPasswordByOTP($passwordOTP: PasswordOTPInput!) {
  resetPasswordByOTP(passwordOTP: $passwordOTP) {
    email
  }
}`
export const Operation_ResetPasswordByOTP = `resetPasswordByOTP`;

export const Query_ResetCredentials = `mutation resetPasswordForAdminUser($user: SigninUserInput!) {
  resetPasswordForAdminUser(user: $user) 
}`
export const Operation_ResetCredentials = `resetPasswordForAdminUser`;


export const Query_UpdateAdminUserProfile = `mutation updateAdminUserProfile($user: UpdateAdminInput!){
  updateAdminUserProfile(user: $user) {
   id
      firstName
      lastName
      userName
      email
      phone
      adminRoleId
      birthDate
      address{
      addressLine1
        addressLine2
        city addressLine1
        state
        zipCode
      }
      clientId
      isActive
  }
}`
export const Operation_UpdateAdminUserProfile = `updateAdminUserProfile`;


export const Query_DRMDonorLookUp = `query DRMDonorLookUp($donorPIIData: DonorSearchInput!) {
  DRMDonorLookUp(donorPIIData: $donorPIIData) {
    nextStart
    isNext
    type
    title
    message
    count
    donors {
      firstName
      lastName
      gender
      birthDate
      lastDonationDate
      bloodType
      donorIdentifier {
        personNumber
        donorId
        personId
      }
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
      emailContacts {
        email
        code
        canContact
      }
      phoneContacts {
        phoneNumber
        code
        ext
        canCall
        canText
      }
    }
  }
}`
export const Operation_DRMDonorLookUp = `DRMDonorLookUp`;


export const Query_GetDonorLoginFailReport = `query getDonorLoginFailReport($loginReport: LoginReportInput!) {
  getDonorLoginFailReport(loginReport: $loginReport) {
    totalRecords
    ActivityLog{
      id
      clientId
      pageName
      activity
      application
      userAgent
      location {
        ip
        state
        country
      }
      error
      email
      userRole
      createdAt
    }
  }
}`
export const Operation_GetDonorLoginFailReport = `getDonorLoginFailReport`;

export const Query_CreateActivityLogForLoginFails = `mutation createLoginFailsActivityLog($activityLog: ActivityLogInput!) {
  createLoginFailsActivityLog(activityLog: $activityLog) {
    id
    clientId
    pageName
    activity
    application
    userAgent
    location {
      ip
      state
      country
    }
    error
    user {
      id
      name
    }
    userRole
    createdAt
  }
}`
export const Operation_CreateActivityLogForLoginFails = `createLoginFailsActivityLog`;

export const Query_CreateActivityLogs = `mutation createActivityLogs($activityLog: ActivityLogInput!) {
    createActivityLogs(activityLog: $activityLog) {
      id
      clientId
      pageName
      activity
      application
      userAgent
      location {
        ip
        state
        country
      }
      error
      user {
        id
        name
      }
      userRole
      createdAt
    }
  }`
export const Operation_CreateActivityLogs = `createActivityLogs`;

  export const Query_AdminLoginReport = `query getAdminLoginReport($loginReport: LoginReportInput!) {
    getAdminLoginReport(loginReport: $loginReport) { 
      totalRecords
      ActivityLog{
        id
        clientId
        pageName
        activity
        application
        userAgent
        user {
          id
          name
          email
          address {
           addressLine1
          }
          gender
          role
          donorId
          birthDate
          isActive
        }
        location {
          ip
          state
          country
        }
        error
        email
        userRole
        createdAt
      }
    }
  }`
  export const Operation_AdminLoginReport = `getAdminLoginReport`;

  export const Query_AllAdminUsersWithoutRoleValidation = `query allAdminUsersWithoutRoleValidation ($adminUser: UserWithPagination!) {
    allAdminUsersWithoutRoleValidation(adminUser: $adminUser) { 
      totalRecords
      adminUsers {
        id
        userName
        firstName
        lastName
        email 
        adminRoleId
        adminRoleName
        phone  
        birthDate
        address {
          addressLine1 
          addressLine2
          city 
          state 
          zipCode
        } 
        clientId
        gender
        isActive
        isAutoCreated
      }  
    }
  }`
  export const Operation_AllAdminUsersWithoutRoleValidation = `allAdminUsersWithoutRoleValidation`;

  export const Query_AdminActivityReport = ` query getAdminActivityReport($activityReport: ReportInput!) {
    getAdminActivityReport(activityReport: $activityReport) { 
      totalRecords
      ActivityLog{
        id
        clientId
        pageName
        activity
        application
        userAgent
        user {
          id
          name
          email
          address {
           addressLine1
          }
          gender
          role
          donorId
          birthDate
          isActive
        }
        location {
          ip
          state
          country
        }
        error
        email
        userRole
        createdAt
      }
    }
  }`
  export const Operation_AdminActivityReport = `getAdminActivityReport`;

  export const Query_CreateAdminDonationScheduledLogs = `mutation createAdminDonationScheduledLogs($activityLog: DonationLogInput!) {
    createAdminDonationScheduledLogs(activityLog: $activityLog) {
      id
      clientId
      pageName
      activity
      application
      userAgent
      location {
        ip
        state
        country
      }
      donor {
        email
        name
        donorId
        address
        birthDate
        wholeBloodNextEligibility
        plateletsNextEligibility
        donationBloodType
        donationDate
        donationSlotTime
        donationLocation
      }
      error
      user {
        id
        name
      }
      userRole
      createdAt
    }
  }`  
  export const Operation_CreateAdminDonationScheduledLogs = `createAdminDonationScheduledLogs`;


  export const Query_GetAdminDonationScheduledReport = `query getAdminDonationsScheduledReport($activityReport: AdminScheduledAppointmentReportInput!) {
    getAdminDonationsScheduledReport(activityReport: $activityReport) { 
      totalRecords
      ActivityLog{
        id
        clientId
        pageName
        activity
        application
        userAgent
        user {
          id
          name
          email
          address {
           addressLine1
           addressLine2 
          }
          gender
          role
          donorId
          birthDate
          isActive
        }
        donor {
          email
          name
          donorId
          address
          birthDate
          wholeBloodNextEligibility
          plateletsNextEligibility
          donationBloodType
          donationDate
          donationSlotTime
          donationLocation
        }
        location {
          ip
          state
          country
        }
        error
        email
        userRole
        createdAt
      }
    }
  }`
  export const Operation_GetAdminDonationScheduledReport = `getAdminDonationsScheduledReport`;

  export const Query_GetDonorActivityReport = `query getDonorActivityReport($activityReport: DonorsReportInput!) {
    getDonorActivityReport(activityReport: $activityReport) { 
      totalRecords
      ActivityLog{
        id
        clientId
        pageName
        activity
        application
        userAgent
        user {
          id
          name
          email
          address {
           addressLine1
          }
          gender
          role
          donorId
          birthDate
          isActive
        }
        location {
          ip
          state
          country
        }
        error
        email
        userRole
        createdAt
      }
    }
  }`
  export const Operation_GetDonorActivityReport = `getDonorActivityReport`;

  export const Query_SetContactPreferences = `mutation setContactPreferences($contactPreference: ContactPreferenceInput!) {
    setContactPreferences(contactPreference: $contactPreference)
  }`;

  export const Query_uploadHealthHistory = `mutation uploadHealthHistory($healthHistory: HealthHistoryInput!){
    uploadHealthHistory(healthHistory: $healthHistory) { 
       result
    }
  }`;

  export const Operation_SetContactPreferences = `setContactPreferences`;


  export const Mutation_DeleteAllHealthHistory = `mutation deleteAllHealthHistory{
    deleteAllHealthHistory { 
       result
    }
  }`;

  export const Operation_DeleteAllHealthHistory = `deleteAllHealthHistory`;