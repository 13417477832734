import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { pageSize, 
  UTCtoDate, 
  createActivityLogs, 
  createDonationActivityLogs, 
  PageName, 
  Activity,
  timeSorting,
  UTCtoDateTime,
  DateUTCtoPST,
  TimeUTCtoPST
 } from "../../../../helper/utils";


import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// 
const SUPPORTED_FILE_TYPES = ["text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", ".csv"];
const checkFileType = (fileObject, supportedTypes) => {
  // console.log("type: ", fileObject);
// console.log("type: ", fileObject && supportedTypes.includes(fileObject.type));
  if (fileObject && fileObject instanceof File) {
      return supportedTypes.includes(fileObject.type);
  }
  // return false;
};  
// Validation schema
export const healthHistoryValidationSchema = Yup.object().shape({
  fileUpload: Yup.mixed()
  .required("File is required")
  .test("imageType", "only CSV type is supported", (value) =>
      checkFileType(value, SUPPORTED_FILE_TYPES)),
});

var selectedSlotForAddOrModify;



// Form
export const HealthHistoryForm = ({
values,
errors,
touched,
handleBlur,
handleSubmit,
isSubmitting,
setFieldValue,
submit
}) => {
  const { user, authToken } = useSelector(({ auth }) => auth);
  const [isActivityLogCall, setIsActivityLogCall] = useState(false);
   
    useEffect(() => {   
        if(!isActivityLogCall && user) {  
            createActivityLogs({Email: user.email, pageName: PageName.DonorManagementDetails, activity: Activity.Visit, error: null, user: user, authToken: authToken });
        setIsActivityLogCall(true);
        }
    }, []); 
    const getFieldCSSClasses = (touched, errors) => {
      const classes = ["form-control"];
      if (touched && errors) {
          classes.push("is-invalid");
      }

      if (touched && !errors) {
          classes.push("is-valid");
      }

      return classes.join(" ");
  };
    return(   
      <Form className="form form-label-right" onSubmit={handleSubmit}>
            <div className="form-group col-lg-3 col-lg-5 col-md-3 col-md-5 mt-3">
            <input
              id="fileUpload"
              type="file"
              name="fileUpload"
              style={{ visibility: "visible" }}
              className={`${getFieldCSSClasses(
                  touched.fileUpload,
                  errors.fileUpload
              )}`}
              onChange={(event) => {
                // console.log("type: ", event.target.files[0].type);
                  event.target.files[0]?.type && setFieldValue("fileUpload", event.target.files[0]);
              }}
              onBlur={handleBlur}
          />
          <span className="error-text">
              {errors.fileUpload && touched.fileUpload && errors.fileUpload}
          </span>
          </div>
          <div class="row">
              <div class="col-12">
              <button
            className="mx-lg-3 font-weight-bold border-0 rounded-10 cta-bg mb-2 p-3 px-lg-5 text-white"
            type="submit"
            disabled={isSubmitting}
        >
            {submit}
            </button>
              </div>
          </div>
        </Form>

    );
}
//
