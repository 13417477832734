import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

 

import { ContentArea, ContentHeadingRow, ParentContent } from "../../../../../../_metronic/layout/components/degree/components/common";
import Alert from 'react-bootstrap/Alert'
import { Loader } from "../../../../../../_metronic/layout/components/Layout";
import { createActivityLogs, PageName, Activity } from "../../../../../helper/utils";
import { HealthHistoryForm, healthHistoryValidationSchema } from "../../health-history-form";
import { Formik } from "formik";
import { uploadHealthHistory, deleteAllHealthHistoryData } from "../../../../../helper/apiDefinations";

// FilterDonorForm

export const HealthHistoryView = () => {
    const { user, authToken } = useSelector(({ auth }) => auth);
    const [loading, setLoading] = useState(false);  
    const [slotsLoading, setSlotsLoading] = useState(false);
    const [fileUploadItem, setFileUploadItem] = useState(null);

    const [isActivityLogCall, setIsActivityLogCall] = useState(false);
    const [errors, setErrors] = useState(null);    

    const history = useHistory();
    
    useEffect(() => {
        if(!isActivityLogCall && user) {  
            createActivityLogs({Email: user.email, pageName: PageName.DonorManagementDetails, activity: Activity.Visit, error: null, user: user, authToken: authToken });
            setIsActivityLogCall(true);
        }
    }, []); 
    
    const handleDismiss = () => setErrors(null);
    let initialValues = {
        fileUpload : null
    }
    const add = async(values, resetForm)=>{
        setLoading(true);
        const healthHistory = { 
            file: { 
              path : "path",
              type : "csv"
            } 
          }

        const dataOFUpload = {
            token: authToken,
            clientId: user.selectedClientId,
            healthHistory: healthHistory,
            imageUrl:values?.fileUpload
        };
        try {
            let res = await uploadHealthHistory(dataOFUpload);
            let data = await res?.data?.data;
            // console.log("data: ", data?.uploadHealthHistory?.result);
            if(data?.uploadHealthHistory?.result){
                Swal.fire({
                    title: 'Health History',
                    icon: 'success',
                    text: "File Uploaded Successfully",
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                })
                .then(function(){ 
                    window.location.reload();
                    })
            }
            else if(!data?.uploadHealthHistory){
                Swal.fire({
                    title: 'Health History',
                    icon: 'error',
                    text: "Uploading Failed",
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                })
            }
        } catch (error) {
            Swal.fire({
                title: 'Health History',
                icon: 'error',
                text: "Uploading Failed",
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
            })
            
        }
        finally{
            
            setTimeout(() => {
                resetForm(initialValues);
                setLoading(false);
                // window.location.reload()
          }, 400);
          
                
        }
    }


    // Delete All Data Api

    const deleteData = async(data2)=>{
        setLoading(true);
        
    
        try {
            let res = await deleteAllHealthHistoryData(data2);
            let data = await res?.data?.data;

            if(data?.deleteAllHealthHistory?.result){
                Swal.fire({
                    title: 'Health History Delete',
                    icon: 'success',
                    text: "History Deleted Successfully",
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    showCloseButton: true,
                })
                
            }
            else if(!data?.deleteAllHealthHistory){
                Swal.fire({
                    title: 'Health History Delete',
                    icon: 'error',
                    text: "Delete Failed",
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                })
            }
        } catch (error) {
            Swal.fire({
                title: 'Health History Delete',
                icon: 'error',
                text: "Delete Failed",
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
            })
            
        }
        finally{
            
            setLoading(false);                        
        }
    }


    function deleteAllData() {
        let data = {
            token: authToken,
            clientId: user.selectedClientId,
        };
        confirmPopUp(data)

    }

    function confirmPopUp(mydata){
        Swal.fire({  
            title: 'Are you sure?',  
            // showDenyButton: true,
            // denyButtonText: `Don't delete`,  
            showCancelButton: true,  
            showCloseButton: true,
            confirmButtonText: `Delete All Data`,              
          }).then((result) => {  
              
              if (result.isConfirmed) {                 
                deleteData(mydata)
                //   Swal.fire('Deleted!', '', 'success')
              } else if (result.isDenied) {    
                  Swal.fire('Delete Unsuccessful', '', 'info')  
               }
          });
    }
      
    return (
        <Fragment className="position-relative">
            {
                loading &&                                                
                <Loader loader={loading}/>
            }
            <ParentContent>
                <ContentHeadingRow
                    title="Health History"
                    buttonTitle="Clear All data"
                    isVisable={true}
                    // onButtonClick={() => history.goBack()}
                    onButtonClick={() => deleteAllData()}
                                      
                />
                <ContentArea>
                    <div class="donor-form-cover">
                    {errors &&
                        <Alert
                            variant="danger"
                            onClose={handleDismiss}
                            dismissible>
                            <Alert.Heading>
                                Oh snap! You got an error!
                            </Alert.Heading>
                            <ul>
                                {errors.map(val =>
                                    <li>
                                        {val.message}
                                    </li>
                                )}
                            </ul>
                        </Alert>}
                    </div>
                    {/* health history-form-cover ends */}
                   
                </ContentArea>
                {initialValues &&
                        
                        <Formik 
                        validationSchema={healthHistoryValidationSchema}
                        initialValues={initialValues}
                        onSubmit={(values, {resetForm}) => add(values, resetForm)}
                        >
                            {({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isSubmitting,
                             setFieldValue
                             /* and other goodies */
                        }) => (
                                <HealthHistoryForm
                                values = {values}
                                errors = {errors}
                                touched = {touched}
                                handleBlur = {handleBlur}
                                handleSubmit = {handleSubmit} 
                                isSubmitting = {isSubmitting}
                                setFieldValue = {setFieldValue}
                                submit={"Submit"}
                                />
                            )}
                        </Formik>}
            </ParentContent>
        </Fragment>
    );
}
