const actions = {
  SET_SITES: "SET_SITES",
  SET_SITE_COUNT: "SET_SITE_COUNT",

  setAllSiteCount: (siteCount) => {
    return ({
      type: actions.SET_SITE_COUNT,
      payload: siteCount
    })
  },
  setSiteTableData: (siteData) => {
    return ({
      type: actions.SET_SITES,
      payload: siteData
    })
  },
}

export default actions;