import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { DegreeDashboardPage } from "../app/modules/DashboarDegree/pages/Dashboard";

import healthHistoryPages from "./modules/HealthHistory/pages/healthHistoryPages";

import marketingAutomation from "./modules/AutomationToolPage/pages/marketingAutomation";

import { PortalSiteAdmin } from "../app/modules/portalSiteAdmin/pages/portalSiteAdmin";
import { AddSiteAdmin } from "./components/addSiteAdmin/addSiteAdmin";

// Degree-Client-Managment-Pages 
const ClientManagmentPage = lazy(() =>
  import("./modules/ClientManagment/pages/clientManagmentPages")
);

// Degree-User-Pages
const UserManagmentPage = lazy(() =>
  import("./modules/UserManagment/pages/userManagmentPages")
);

// Degree-Role-Pages
const RoleManagmentPage = lazy(() =>
  import("./modules/RoleManagment/pages/roleManagmentPages")
);

// Degree-Donor-Pages
const DonorManagementPage = lazy(() =>
  import("./modules/DonorManagement/pages/donorManagementPages")
);

// Marketing Automation
const MarketingAutomation = lazy(() =>
  import("./modules/MarketingAutomation/pages/MarketingAutomationPages")
);


// Marketing Automation
// const AutomationToolPage = (() =>
//   import("./modules/AutomationToolPage/pages/index")
// ); 


// Reports-Pages
const Reports = lazy(() =>
  import("./modules/Reports/pages/reportsPages")
);

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const ContentManagementSystemPage = lazy(() =>
  import("./modules/ContentManagementSystem/pages/contentManagmentSystemPages")
);

export default function BasePage() {
  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */}
        <Redirect exact from="/" to="/healthhistory" />

        {/* <ContentRoute path="/dashboard" component={DegreeDashboardPage} /> */}

        <ContentRoute path="/healthhistory" component={healthHistoryPages} />

        <ContentRoute path="/clientManagement" component={ClientManagmentPage} />

        <ContentRoute path="/userManagement" component={UserManagmentPage} />

        <ContentRoute path="/rolesManagement" component={RoleManagmentPage} />

        <ContentRoute path="/donorManagement" component={DonorManagementPage} />

        <ContentRoute path="/contentManagement" component={ContentManagementSystemPage} />

        {/* <ContentRoute path="/marketingAutomation" component={MarketingAutomation} />  */}
        <ContentRoute path="/marketingAutomation" component={marketingAutomation} />

        <ContentRoute path="/reports" component={Reports} />        

        {/* ali imtiaz */}
        {/* <ContentRoute path="/clientManagement" component={PortalSiteAdmin} /> */}
        <ContentRoute path="/addSiteAdmin" component={AddSiteAdmin} />

        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />

        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        
        {/*         
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/user-profile" component={UserProfilepage} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
