const status = [
    // {
    //     "id": "0",
    //     "name": "State",
    //     "code": ""
    // },
    {
        "id": "1",
        "name": "Active"
        // "code": "AL"
    },
    {
        "id": "1",
        "name": "Active"
        // "code": "AL"
    },
    {
        "id": "2",
        "name": "Inactive"
        // "code": "AK"
    },
    

]

// export const genderOptData = [
//     { code: '', name: 'Select' },
//     { code: 'M', name: 'Male' },
//     { code: 'F', name: 'Female' }
// ]

export const statusOptData = (status);