import axios from "axios";
import { BASE_URL, CC_BASE_URL, Stage_BASE_URL, deviceId, GQL_PUBLIC, GQL, userAgent } from "./webServices";
import {
  // Query_CreateAdminRole, Operation_CreateAdminRole,
  // Query_AllAdminRoles, Operation_AllAdminRoles,
  Query_AdminUserByEmail, Operation_AdminUserByEmail,
  Query_AllAdminUsers, Operation_AllAdminUsers,
  Query_AllSite, Operation_AllSite,
  Query_SearchSite, Operation_SearchSite,
  Query_AllSiteEnablements, Operation_AllSiteEnablements,
  Query_AllDonors, Operation_AllDonors,
  Query_SiteYearlyReport, Operation_SiteYearlyReport,
  Query_SiteByState, Operation_SiteByState,
  Query_DonorsByState_Count, Operation_DonorsByState_Count,
  Query_YearlyDonorReport, Operation_YearlyDonorReport,
  Query_RoleById, Operation_RoleById,
  Query_UserRoleById, Operation_UserRoleById,
  Query_SiteById, Operation_SiteById,
  Query_TotalDonors, Operation_TotalDonors,

  Mutation_changeSiteStatus, Operation_changeSiteStatus,
  Mutation_CreateSite, Operation_CreateSite,
  Mutation_UpdateSite, Operation_UpdateSite,
  Query_SearchAdminUser, Operation_SearchAdminUser,

  // admin roles
  Query_AllAdminRoles, Operation_AllAdminRoles,
  Query_SearchAdminRoles, Operation_SearchAdminRoles,
  Query_CreateSuperUser, Operation_CreateSuperUser,
  Query_AdminUserById, Operation_AdminUserById,
  Mutation_UpdateAdminUser, Operation_UpdateAdminUser,
  Query_SiteEnablement, Operation_SiteEnablement,
  Mutation_CreateAdminRole, Operation_CreateAdminRole,
  Mutation_UpdateAdminRole, Operation_UpdateAdminRole,
  Mutation_DeleteAdminRole, Operation_DeleteAdminRole,
  Query_AllAdminRolesWithOutRoleValidation, Operation_AllAdminRolesWithOutRoleValidation,
  Query_DonorSearch, Operation_DonorSearch,

  Query_AllAds, Operation_AllAds,
  Query_SearchAds, Operation_SearchAds,
  Query_AdsById, Operation_AdsById,
  Query_AdsDeleteById, Operation_AdsDeleteById,
  Query_AllEmailTemplate,Operation_AllEmailTemplate,


  Query_DonorById, Operation_DonorById,
  Mutation_UploadAdsFile, Mutation_UpdateAdsFile,
  Mutation_GetJWTByEmail, Operation_GetJWTByEmail,

  Query_ConfirmedAppointments, Operation_ConfirmedAppointments,
  Query_UnconfirmedAppointments, Operation_UnconfirmedAppointments,
  Mutation_TemplateDeleteById, Operation_TemplateDeleteById,

  Query_EmailTemplateAll, Operation_EmailTemplateAll,
  Query_EmailTemplateSearch, Operation_EmailTemplateSearch,
  Query_EmailTemplateById, Operation_EmailTemplateById,
  Mutation_EmailTemplateDelete, Operation_EmailTemplateDelete,
  Mutation_EmailTemplateUpdate, Operation_EmailTemplateUpdate,
  Mutation_EmailTemplateAdd, Operation_EmailTemplateAdd,
  Mutation_AddDonor, Operation_AddDonor,

  Query_AllAvailableAppointments, Operation_AllAvailableAppointments,
  Query_AllDriveLocations, Operation_AllDriveLocations,

  Query_ModifyAppointmentByAdmin, Operation_ModifyAppointmentByAdmin,
  Query_AddAppointmentByAdmin, Operation_AddAppointmentByAdmin,
  Query_CancelAppointmentByAdmin, Operation_CancelAppointmentByAdmin,
  
  Query_CreateOTPForResetPassword, Operation_CreateOTPForResetPassword,
  Query_ResetPasswordByOTP, Operation_ResetPasswordByOTP,

  Query_ResetCredentials, Operation_ResetCredentials,
  Query_UpdateAdminUserProfile, Operation_UpdateAdminUserProfile,
  Query_DRMDonorLookUp, Operation_DRMDonorLookUp,

  Query_GetDonorLoginFailReport, Operation_GetDonorLoginFailReport,
  Query_CreateActivityLogForLoginFails, Operation_CreateActivityLogForLoginFails,
  Query_CreateActivityLogs, Operation_CreateActivityLogs,
  Query_AdminLoginReport, Operation_AdminLoginReport,
  Query_AllAdminUsersWithoutRoleValidation, Operation_AllAdminUsersWithoutRoleValidation,
  Query_AdminActivityReport, Operation_AdminActivityReport,
  Query_CreateAdminDonationScheduledLogs, Operation_CreateAdminDonationScheduledLogs,
  Query_GetAdminDonationScheduledReport, Operation_GetAdminDonationScheduledReport,
  Query_GetDonorActivityReport, Operation_GetDonorActivityReport,

  Query_SetContactPreferences, Operation_SetContactPreferences, Query_uploadHealthHistory,
  Mutation_DeleteAllHealthHistory, Operation_DeleteAllHealthHistory
} from "./queries";
import { da } from "date-fns/locale";

// Client/site
// GET All Site
export async function allSite(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AllSite,
      variables: {
        site: data.pagination
      },
      operationName: Operation_AllSite,
    }
  });
  return req;
}
// GET Search Site
export async function searchSite(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_SearchSite,
      variables: {
        site: data.variables
      },
      operationName: Operation_SearchSite,
    }
  });
  return req;
}
// GET All Site Enablement
export async function siteEnablements(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AllSiteEnablements,
      variables: {
      },
      operationName: Operation_AllSiteEnablements,
    }
  });
  return req;
}
// GET Site By State
export async function siteSearchByState(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_SiteByState,
      variables: {
        site: data.site
      },
      operationName: Operation_SiteByState,
    }
  });
  return req;
}
// GET Site By ID
export async function siteById(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_SiteById,
      variables: {
        site: data.id
      },
      operationName: Operation_SiteById,
    }
  });
  return req;
}
// ADD Site 
export async function createSite(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_CreateSite,
      variables: {
        site: data.siteCreate
      },
      operationName: Operation_CreateSite,
    }
  });
  return req;
}
// UPDATE Site 
export async function updateSite(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_UpdateSite,
      variables: {
        site: data.siteUpdate
      },
      operationName: Operation_UpdateSite,
    }
  });
  return req;
}
// CHANGE Site Status
export async function changeSiteStatus(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_changeSiteStatus,
      variables: {
        site: data.id
      },
      operationName: Operation_changeSiteStatus,
    }
  });
  return req;
}
// GET Site Yearly Report
export async function siteYearlyReport(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_SiteYearlyReport,
      variables: {
        report: data.year
      },
      operationName: Operation_SiteYearlyReport,
    }
  });
  return req;
}

// Donor
// GET All Donor
export async function allDonor(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AllDonors,
      variables: {
        donor: data.pagination
      },
      operationName: Operation_AllDonors,
    }
  });
  return req;
}
// GET Total Donors Count
export async function totalDonor(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_TotalDonors,
      variables: {
      },
      operationName: Operation_TotalDonors,
    }
  });
  return req;
}
// GET Donor Yearly Report
export async function donorYearlyReport(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_YearlyDonorReport,
      variables: {
        year: data.year
      },
      operationName: Operation_YearlyDonorReport,
    }
  });
  return req;
}
// GET Donor By State
export async function donorSearchByState_Count(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_DonorsByState_Count,
      variables: {
        state: data.stateCode
      },
      operationName: Operation_DonorsByState_Count,
    }
  });
  return req;
}

// ADMIN USER
// All Admin Users 
export async function allAdminUsers(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AllAdminUsers,
      variables: {
        adminUser: data.pagination
      },
      operationName: Operation_AllAdminUsers,
    }
  });
  return req;
}
// Create Admin Users 
export async function createAdminUsers(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_CreateSuperUser,
      variables: {
        user: data.variables
      },
      operationName: Operation_CreateSuperUser,
    }
  });
  return req;
}

// ADMIN ROLE
// GET User Admin Role By Id
export async function userAdminRold(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_UserRoleById,
      variables: {
        adminRole: data.variables
      },
      operationName: Operation_UserRoleById,
    }
  });
  return req;
}
// GET Admin Role By Id
export async function AdminRold(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_RoleById,
      variables: {
        adminRole: data.variables
      },
      operationName: Operation_RoleById,
    }
  });
  return req;
}
// Get Search Admin User
export async function searchAdminUser(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_SearchAdminUser,
      variables: {
        adminUser: data.variables
      },
      operationName: Operation_SearchAdminUser,
    }
  });
  return req;
}
// All Admin Role
export async function allAdminRoles(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AllAdminRoles,
      variables: {
        adminRole: data.pagination
      },
      operationName: Operation_AllAdminRoles,
    }
  });
  return req;
}
// Search Admin Role
export async function searchAdminRoles(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_SearchAdminRoles,
      variables: {
        adminRole: data.variables
      },
      operationName: Operation_SearchAdminRoles,
    }
  });
  return req;
}
// GET Admin User By ID
export async function adminUserById(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AdminUserById,
      variables: {
        adminUser: data.id
      },
      operationName: Operation_AdminUserById,
    }
  });
  return req;
}
// GET All Site Enablement for admin role
export async function siteEnablement(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_SiteEnablement,
      operationName: Operation_SiteEnablement,
    }
  });
  return req;
}
// UPDATE Admin User By ID
export async function updateAdminUser(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_UpdateAdminUser,
      variables: {
        user: data.user
      },
      operationName: Operation_UpdateAdminUser,
    }
  });
  return req;
}
// CREATE ADMIN ROLES
export async function createAdminRole(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_CreateAdminRole,
      variables: {
        adminRole: data.adminRole
      },
      operationName: Operation_CreateAdminRole,
    }
  });
  return req;
}
// UPDATE ADMIN ROLES
export async function updateAdminRole(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_UpdateAdminRole,
      variables: {
        adminRole: data.adminRole
      },
      operationName: Operation_UpdateAdminRole,
    }
  });
  return req;
}
// UPDATE ADMIN ROLES
export async function deleteAdminRole(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_DeleteAdminRole,
      variables: {
        adminRole: data.id
      },
      operationName: Operation_DeleteAdminRole,
    }
  });
  return req;
}
export async function allAdminRolesWithOutRoleValidation(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AllAdminRolesWithOutRoleValidation,
      variables: {
        adminRole: data.pagination
      },
      operationName: Operation_AllAdminRolesWithOutRoleValidation,
    }
  });
  return req;
}

export async function allDonorSearch(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_DonorSearch,
      variables: {
        donor: data.variables
      },
      operationName: Operation_DonorSearch,
    }
  });
  return req;
}

export async function allAds(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AllAds,
      variables: {
        ads: data.pagination
      },
      operationName: Operation_AllAds,
    }
  });
  return req;
}

export async function searchAds(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_SearchAds,
      variables: {
        ads: data.variables
      },
      operationName: Operation_SearchAds,
    }
  });
  return req;
}

export async function getAdsById(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AdsById,
      variables: {
        id: data.id
      },
      operationName: Operation_AdsById,
    }
  });
  return req;
}

export async function deleteAdsById(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AdsDeleteById,
      variables: {
        id: data.id
      },
      operationName: Operation_AdsDeleteById,
    }
  });
  return req;
}

export async function donorById(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_DonorById,
      variables: {
        id: data.id
      },
      operationName: Operation_DonorById,
    }
  });
  return req;
}

// createAds
export async function createAds(data) {
  let formData = new FormData();
  const variables = JSON.stringify({
    cmsAds: data.createAdsData.cmsAds
  });

  formData.append("query", Mutation_UploadAdsFile);
  formData.append("variables", variables);
  formData.append("file", data.createAdsData.imageUrl);

  // return

  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
      "content-type": `${'multipart/form-data; boundary=---011000010111000001101001'}`,
      "Cookie": `${'connect.sid=s%253A0f295b70-4a5e-11e8-bb0d-afeef0102692.ViqlZFeYp0OnpnQsx4Ps10EzM1yFYucEbtr7SBCRjQk'}`,
    },
    data: formData
  });
  return req;
}

// updateAds
export async function updateAds(data) {
  let formData = new FormData();
  const variables = JSON.stringify({
    cmsAds: data.updateAdsData.cmsAds
  });

  formData.append("query", Mutation_UpdateAdsFile);
  formData.append("variables", variables);
  formData.append("file", data.updateAdsData.imageUrl);

  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
      "content-type": `${'multipart/form-data; boundary=---011000010111000001101001'}`,
      "Cookie": `${'connect.sid=s%253A0f295b70-4a5e-11e8-bb0d-afeef0102692.ViqlZFeYp0OnpnQsx4Ps10EzM1yFYucEbtr7SBCRjQk'}`,
    },
    data: formData
  });
  return req;
}

// get JWT by Email 
export async function JwtByEmail(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_GetJWTByEmail,
      variables: {
        email: data.email
      },
      operationName: Operation_GetJWTByEmail,
    }
  });
  return req;
}

// get Unconfirmed Appointment
export async function GetUnconfirmedAppointment(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_UnconfirmedAppointments,
      variables: { 
      },
      operationName: Operation_UnconfirmedAppointments,
    }
  });
  return req;
}

// get Confirmed Appointment
export async function GetConfirmedAppointment(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_ConfirmedAppointments,
      variables: { 
      },
      operationName: Operation_ConfirmedAppointments,
    }
  });
  return req;
} 

// get Email Template
export async function GetAllEmailTemplates(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_EmailTemplateAll,
      variables: {
        emailTemplate: data.variable 
      },
      operationName: Operation_EmailTemplateAll,
    }
  });
  return req;
}

// search Email Template
export async function SearchEmailTemplates(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_EmailTemplateSearch,
      variables: {
        emailTemplate: data.variable 
      },
      operationName: Operation_EmailTemplateSearch,
    }
  });
  return req;
}

export async function deleteEmailTemplateById(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_EmailTemplateDelete,
      variables: {
        emailTemplate: data.emailTemplate
      },
      operationName: Operation_EmailTemplateDelete,
    }
  });
  return req;
}

// Email Template get by Id
export async function EmailTemplateById(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_EmailTemplateById,
      variables: {
        emailTemplate: data.variable 
      },
      operationName: Operation_EmailTemplateById,
    }
  });
  return req;
}
// UPDATE Email Template 
export async function EmailTemplateUpdate(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_EmailTemplateUpdate,
      variables: {
        emailTemplate: data.UpdatedData
      },
      operationName: Operation_EmailTemplateUpdate,
    }
  });
  return req;
}

// ADD Email Template 
export async function EmailTemplateAdd(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_EmailTemplateAdd,
      variables: {
        emailTemplate: data.variables
      },
      operationName: Operation_EmailTemplateAdd,
    }
  });
  return req;
}


// ADD Donor 
export async function AddDonor(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL_PUBLIC}`,
    method: "POST",
    headers: {
      // 'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_AddDonor,
      variables: {
        user: data.variables
      },
      operationName: Operation_AddDonor,
    }
  });
  return req;
}

// get All Drive
export async function AllDrive(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL_PUBLIC}`,
    method: "POST",
    headers: {
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AllDriveLocations,
      variables: {
        drive: data.variables
      },
      operationName: Operation_AllDriveLocations,
    }
  });
  return req;
}

// Get all Slots
export async function AllSlots(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL_PUBLIC}`,
    method: "POST",
    headers: {
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AllAvailableAppointments,
      variables: {
        appointment: data.variables
      },
      operationName: Operation_AllAvailableAppointments,
    }
  });
  return req;
}

export async function CancelAppointment(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_CancelAppointmentByAdmin,
      variables: {
        appointment: data.variables
      },
      operationName: Operation_CancelAppointmentByAdmin,
    }
  });
  return req;
}

export async function ModifyAppointment(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_ModifyAppointmentByAdmin,
      variables: {
        appointment: data.variables
      },
      operationName: Operation_ModifyAppointmentByAdmin,
    }
  });
  return req;
}

export async function AddAppointment(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AddAppointmentByAdmin,
      variables: {
        appointment: data.variables
      },
      operationName: Operation_AddAppointmentByAdmin,
    }
  });
  return req;
}

export async function GetOTPForResetPassword(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL_PUBLIC}`,
    method: "POST",
    headers: {
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_CreateOTPForResetPassword,
      variables: {
        emailOTP: data.variables
      },
      operationName: Operation_CreateOTPForResetPassword,
    }
  });
  return req;
}

export async function ResetPasswordByOTP(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL_PUBLIC}`,
    method: "post",
    headers: {
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_ResetPasswordByOTP,
      variables: {
        passwordOTP: data.variables
      },
      operationName: Operation_ResetPasswordByOTP,
    }
  });
  return req;
}

export async function ResetCredentials (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_ResetCredentials,
      variables: {
        user: data.variables
      },
      operationName: Operation_ResetCredentials,
    }
  });
  return req;
}

export async function UpdateProfile (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_UpdateAdminUserProfile,
      variables: {
        user: data.user
    },
      operationName: Operation_UpdateAdminUserProfile,
    }
  });
  return req;
}


export async function DRMDonorLookup (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL_PUBLIC}`,
    method: "POST",
    headers: {
      
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
        data: {
          query: Query_DRMDonorLookUp,
          variables: {            
            donorPIIData: data.donorPIIData,
          },
          operationName: Operation_DRMDonorLookUp,
        }
      });
      return req;
    }


export async function GetDonorLoginFailReport (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
        data: {
          query: Query_GetDonorLoginFailReport,
          variables: {
              loginReport: data.variables           
          },
          operationName: Operation_GetDonorLoginFailReport,
        }
      });
      return req;
    }
    
export async function addActivityLogsForLoginFails (data) { 
  const req = await axios({
    url: `${BASE_URL}/${GQL_PUBLIC}`,
    method: "POST",
    headers: { 
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_CreateActivityLogForLoginFails,
      variables: {
        activityLog: data.variables           
      },
      operationName: Operation_CreateActivityLogForLoginFails,
    }
  });
  return req;
}
   
export async function addActivityLogs (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_CreateActivityLogs, 
      variables: {
        activityLog: data.variables           
      },
      operationName: Operation_CreateActivityLogs,
    }
  });
  return req;
}

export async function GetAdminLoginReport (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
        data: {
          query: Query_AdminLoginReport,
          variables: {
              loginReport: data.variables           
          },
          operationName: Operation_AdminLoginReport,
        }
      });
      return req;
    }
    
export async function GetAdminUserWithoutRoleValidation (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
      data: {
        query: Query_AllAdminUsersWithoutRoleValidation,
        variables: {
          adminUser: data.variables           
        },
        operationName: Operation_AllAdminUsersWithoutRoleValidation,
      }
    });
    return req;
  }

export async function GetAdminActivityReport (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_AdminActivityReport,
      variables: {
        activityReport: data.variables           
      },
      operationName: Operation_AdminActivityReport,
    }
    });
    return req;
  }

export async function addAdminDonationScheduledLogs (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_CreateAdminDonationScheduledLogs, 
      variables: {
        activityLog: data.variables           
      },
      operationName: Operation_CreateAdminDonationScheduledLogs,
    }
  });
  return req;
}
  
export async function adminDonationScheduledReport (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_GetAdminDonationScheduledReport, 
      variables: {
        activityReport: data.variables           
      },
      operationName: Operation_GetAdminDonationScheduledReport,
    }
  });
  return req;
}

export async function getDonorActivityReport (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_GetDonorActivityReport, 
      variables: {
        activityReport: data.variables           
      },
      operationName: Operation_GetDonorActivityReport,
    }
  });
  return req;
}

export async function setContactPreferences (data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
    },
    data: {
      query: Query_SetContactPreferences, 
      variables: {
        contactPreference: data.variables           
      },
      operationName: Operation_SetContactPreferences,
    }
  });
  return req;
}

export async function uploadHealthHistory (data) {
    let formData = new FormData();
  const variables = JSON.stringify({
    healthHistory: data.healthHistory
  });

  formData.append("query", Query_uploadHealthHistory);
  formData.append("variables", variables);
  formData.append("file", data.imageUrl);

  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      "client-id": `${data.clientId}`,
      "device-id": `${deviceId}`,
      "content-type": `${'multipart/form-data; boundary=---011000010111000001101001'}`,
      "Cookie": `${'connect.sid=s%253A0f295b70-4a5e-11e8-bb0d-afeef0102692.ViqlZFeYp0OnpnQsx4Ps10EzM1yFYucEbtr7SBCRjQk'}`,
    },
    data: formData
  });
  return req;
}



export async function deleteAllHealthHistoryData(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL}`,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${data.token}`,
      "User-Agent": `${userAgent}`,
      // "Content-Type": 'application/json',
      "client-id": `${data.clientId}`,
      // "device-id": `${deviceId}`,
    },
    data: {
      query: Mutation_DeleteAllHealthHistory,
      // variables: {
      //   emailTemplate: data.emailTemplate
      // },
      operationName: Operation_DeleteAllHealthHistory,
    }
  });
  return req;
}