import React, { useState } from "react";
import { useFormik, Form, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import Swal from 'sweetalert2';
import { GetOTPForResetPassword, ResetPasswordByOTP } from "../../../helper/apiDefinations"

const initialValues = {
  otp: "",
  password: "",
  confirmpassword: "",
};

function ResetPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);

  
  const [isDisable, setisDisable] = useState(false);
  const [resErrors, setErrors] = useState(null);

  const ResetPasswordSchema = Yup.object().shape({

    otp: Yup.string()
      // .matches(/^[0-9]+$/, "Must be only digits")
      // .min(4, 'OTP Code Must be exactly 4 digits')
      // .max(4, 'OTP Code Must be exactly 4 digits')
      .required("OTP Code is required"),
    password: Yup.string()
      .matches(passwordValidation, "Use atleast 8 characters, should be 1 numeric, 1 capital Alphabet, 1 spectial character.")
      .required("Use atleast 8 characters, should be 1 numeric, 1 capital Alphabet, 1 spectial character."),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required("Use atleast 8 characters, should be 1 numeric, 1 capital Alphabet, 1 spectial character."),

  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const passwordValidation = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/


  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      

      onSubmitResetPass(values)
        .then(() => setIsRequested(true))
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values }
            )
          );
        });
    },
  });

  const onSubmitResetPass = async (val) => {
    // setisDisable(true);

    try {

        const data = {
          clientId: 2, //user.ClientId,
          variables:{
            otp: val.otp,
            password: val.password 
          }
        }
        const res = await ResetPasswordByOTP(data);
        if (res.data.errors) {
            setErrors(res.data.errors);
            window.scrollTo(0, 0);
            Swal.fire(
                  '',
                  `${res.data.errors[0].message}`,
                  'error'
              );
            return;
        }
        if (res.data.data?.resetPasswordByOTP) {
          
          Swal.fire(`Message`, 'Password Reset Successful.', 'success')
          .then(okay => {
            if (okay) {
             window.location.href = "/auth/login";
           }});
             
        }
    } catch (error) {
        
    } finally {
        // setisDisable(false);
    }
  }
  

  // const onResetPasswordReq = async (data) => {
  //   try {
      
  //     var tempData = {
  //       clientId:0,
  //       variables: {
  //         email:data
  //       }
  //     }
      
  //     const res = await GetOTPForResetPassword(tempData);
  //     return res;
  //   } catch (error) {
  //   } finally {
  //       // setLoading(false)
  //   }
  // }

  return (
    <>
      {isRequested && <Redirect to="/auth/reset-password" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password ?</h3>
            <div className="text-muted font-weight-bold">
              Change your password
            </div>
          </div>
          <form
            
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">

              <label className="font-weight-bold">OTP*</label>
              <input
                type="text"
                placeholder="Enter OTP"
                className={`form-control form-control-solid h-auto py-5 px-6 border shadow-xs ${getInputClasses(
                  "text"
                )}`}
                name="otp"
                {...formik.getFieldProps("otp")}
              />

              {formik.touched.otp && formik.errors.otp ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.otp}</div>
                </div>
              ) : null}
            </div>

            <div className="form-group fv-plugins-icon-container">
              <label className="font-weight-bold">Enter Password</label>
              <input
                type="password"
                placeholder="Min 8 characters, with 1 number, capital and special"
                className={`form-control form-control-solid h-auto py-5 px-6 border shadow-xs ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>

            <div className="form-group fv-plugins-icon-container">
              <label className="font-weight-bold">Confirm Password</label>
              <input
                type="password"
                placeholder="Min 8 characters, with 1 number, capital and special"
                className={`form-control form-control-solid h-auto py-5 px-6 border shadow-xs ${getInputClasses(
                  "confirm_password"
                )}`}
                name="confirm_password"                             
                {...formik.getFieldProps("confirm_password")}
              />
              {formik.touched.confirm_password && formik.errors.confirm_password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.confirm_password}</div>
                </div>
              ) : null}
            </div>

            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_reset_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
                onClick={onSubmitResetPass()}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_reset_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
