/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { authToken, user, menu } = useSelector(({ auth }) => auth);

  const [SuperAdminSideMenu, setSuperAdminSideMenu] = useState([]);

  useEffect(() => {
    setSuperAdminSideMenu(menu);
  }, [menu])
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {SuperAdminSideMenu && SuperAdminSideMenu.map((value, index) => {
          return <li
            key={value}
            className={`menu-item menu-item-submenu ${getMenuItemActive(value.Url, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link menu-toggle" to={`/${value.Url}`}>

              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">{value.Name.toUpperCase()}</span>
              {value.Children && value.Children.slice(0, 1).map(i => {
                return <i class="menu-arrow"></i>
              })}

            </NavLink>
            {value.Children && value.Children.map(i => {
              return <div className="menu-submenu ">
                <i class="menu-arrow"></i>
                <ul className="menu-subnav">
                  <li 
                  aria-haspopup="true"
                  className={`menu-item ${getMenuItemActive(`/${i.url}`)}`}>
                    
                    <NavLink className="menu-link" to={`/${i.url}`}>
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">{i.name.toUpperCase()}</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            })}
          </li>
        })}
        {/*end::1 Level*/}
      </ul>
      {/* end::Menu Nav */}
    </>
  );

}
