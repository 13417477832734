import actions from './dashboardActions';

const initialState = {
    sites_count: null,
    sites: null,
    all_donorCount: null,
};


export default function reducre(state = initialState, { type, payload }) {
    switch (type) {
        case actions.SET_SITE_COUNT:
            return {
                ...state,
                sites_count: payload
            }
        case actions.SET_SITES:
            return {
                ...state,
                sites: payload
            }
        default:
            return state;
    }
}