import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from 'moment';
import './style.css';
import { numberWithDash } from "../../helper/utils";

export function SiteAdminPortal({ className, clientId, data, name }) {


  return (
    <div className={`card card-custom ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 py-5">

      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-0">
        {/* begin::Table */}
        <div className="table-responsive">
          <table
            className="table table-head-custom table-vertical-center max-h-300"
            id="kt_advance_table_widget_1"
          >

            <tr>
              <th className="px-0" style={{ minWidth: "150px" }} >Site Name</th>
              <th className="px-0" style={{ minWidth: "125px" }} >Phone</th>
              <th className="px-0 text-wrap" style={{ minWidth: "100px" }} >Email Address</th>
              <th className="px-0" style={{ minWidth: "130px" }} >Domain</th>
              <th className="px-0 text-center" style={{ minWidth: "120px" }} >Status</th>
              <th className="px-0" style={{ minWidth: "150px" }} >Action</th>
            </tr>
            <tbody>

              {(data && clientId === "0") && data.map((val, index) => {
                return (

                  <tr>
                    <td>{val.name}</td>
                    <td>{val.phoneNumber} </td>
                    <td>{val.email} </td>
                    <td><a href={val.domain} className="text-dark" target="_blank">{val.domain}</a> </td>
                    <td className="text-center">
                      {
                        val.isActive ?
                          <div className="text-success px-3">Active</div> :
                          <div className="text-danger px-3">Inactive</div>
                      }

                    </td>
                    <td>
                      {val.isActive ?
                        <button className="bg-danger text-white border mx-2 px-3 m-w-70 rounded-10">Inactive</button> :
                        <button className="bg-success text-white border mx-2 px-3 m-w-70 rounded-10">Active</button>
                      }
                      <button className="bg-warning text-white border mx-2 px-3 m-w-70 rounded-10">Edit</button>
                    </td>
                  </tr>
                )

              }
              )}


              {/* {(data && clientId === "0") && data.map((val, index) => {
                return (

                  <tr>

                   
                    <td>{val.name}</td>
                    <td>{val.phoneNumber} </td>
                    <td>{val.email} </td>
                    <td>{val.domain} </td>
                    <td className="text-center">
                      {
                        val.isActive ?
                          <button className="bg-success text-white border px-3 rounded-10">Active</button> :
                          <button className="bg-secondary text-secondary border px-3 rounded-10">inactive</button>
                      }

                    </td>
                    <td>
                      <button className="bg-danger text-white border mx-2 px-3 rounded-10">Delete</button>
                      <button className="bg-warning text-white border mx-2 px-3 rounded-10">Edit</button></td>
                  </tr>
                )

              }
              )} */}

            </tbody>
          </table>
        </div>
        {/* end::Table */}
      </div>
      {/* end::Body */}
    </div>
  );
}

const TableRowSite = ({ name, number, state, status, domain }) => (
  <tr>
    <td className="pl-0">
      <p className="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize">
        {name}
      </p>
    </td>
    <td>
      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
        {numberWithDash(number)}
      </span>
    </td>
    <td>
      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
        {state}
      </span>
    </td>
    <td className="pr-0 text-right">
      <span
        className={`text-dark-75 font-weight-bolder d-block font-size-lg font-weight-bold`}
        className={status ? "text-color-active" : "text-color-inactive"}
      >
        {status ? "Active" : "Inactive"}
      </span>
    </td>
  </tr>
);



const allSiteData = ({ siteName, number, email, domain, status, action }) => (
  <tr>
    <td className="pl-0">
      <p className="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize">
        {siteName}
      </p>
    </td>
    <td>
      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
        {numberWithDash(number)}
      </span>
    </td>
    <td>
      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
        {email}
      </span>
    </td>
    <td>
      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
        {domain}
      </span>
    </td>

    <td className="pr-0 text-right">
      <span
        className={`text-dark-75 font-weight-bolder d-block font-size-lg font-weight-bold`}
        className={status ? "text-color-active" : "text-color-inactive"}
      >
        {status ? "Active" : "Inactive"}
      </span>
    </td>
    <td>
      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
        {action}
      </span>
    </td>
  </tr>
);
