import React, { Suspense } from "react";
import { Switch } from "react-router-dom";

import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";

import {HealthHistoryView} from './healthhistory/healthHistory-view/healthHistoryView';


export default function healthHistoryPages() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <ContentRoute
                    path="/healthhistory"
                    component={HealthHistoryView}
                />
            </Switch>
        </Suspense>
    );
}
