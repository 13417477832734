import React, { useEffect, useState } from "react";
import { useFormik, Formik, Field } from "formik";
import { Nav, Tab } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { InputText, SelectBox, DateBox } from '../../components/generalComponents/inputtext/index.js';
import './style.css';
import { numberWithDash } from "../../helper/utils";
import ContentHeading from "../generalComponents/contentHeading";
import { siteEnablements } from "../../helper/apiDefinations";
import { genderOptData, stateOptData } from "../../helper/us-state";
import { statusOptData } from "../../helper/status";
import * as Yup from 'yup';


var apiCall = "allSiteEnablements";

export function AddSiteAdmin({ className, clientId, data, name }) {
  const { user, authToken } = useSelector(({ auth }) => auth);
  const [siteEnablementData, setSiteEnablementData] = useState([]);

  // api-stie-enablement
  const callSiteEnablement = async (val) => {
    apiCall = "allSiteEnablements";
    let args = {
      clientId: user?.selectedClientId, token: authToken
    };
    try {
      const res = await siteEnablements(args);
      const siteEnablementData = res?.data?.data?.allSiteEnablements
      setSiteEnablementData(siteEnablementData)
    } catch (error) {
      alert(error.message);
    } finally {

    }

  }

  const formik = useFormik({
    initialValues: {
      email: '',
      SiteName: '',
      phone: '',
      email: '',
      domain: '',
      status: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateArea: '',
      zipcode: '',
      enablement: []
    },
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
    },
  });


  useEffect(() => {
    callSiteEnablement();
  }, [])

  return (
    <div className={`card card-custom ${className} p-3 border-0`}>

      <ContentHeading title="Add Client" />


      {/* <h1>Add Client</h1> */}
      <Formik
        initialValues={{
          searchText: "",
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-7 col-xl-6">
                <div>
                  <InputText
                    className
                    lable="Site Name*"
                    _id="InputSiteName"
                    type="text"
                    // defaultValue={SiteName}
                    placeholder="Site Name"
                    isIcon={false}
                    name="SiteName"
                    onChange={formik.handleChange}
                    value={formik.values.SiteName}
                  // onChangeText={(action, value) => setlastName(value)}
                  // value={lastName}
                  // validationRef={register({
                  //   required: true,
                  //   pattern: validText
                  // })}
                  />
                  {/* {errors.lastName?.type === "required" && <ErrorText message={errMsg.lastName} />}
                  {errors.lastName?.type === "pattern" && <ErrorText message={errMsg.textOnly} />} */}
                </div>

                <div>
                  <InputText
                    lable="Phone Number*"
                    _id="InputPhoneNumber"
                    type="number"
                    placeholder="+1 213 000 000"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  // validationRef={register({
                  //     required: true,
                  //     pattern: validPhonenumber
                  // })}
                  />
                  {/* {errors.phone?.type === "required" && <ErrorText message={errMsg.phone} />}
                                            {errors.phone?.type === "pattern" && <ErrorText message={errMsg.validPhonenumber} />} */}
                </div>

                <div>
                  <InputText
                    lable="Email Address*"
                    _id="InputEmail"
                    type="email"
                    placeholder="user@example.com"
                    isRequired={true}
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  // onChangeText={(action, value) => setemail(value)}
                  // value={email}
                  // defaultValue={email}
                  // validationRef={register({
                  //   required: true,
                  //   pattern: validEmail
                  // })}
                  />
                  {/* {errors.email?.type === "required" && <ErrorText message={errMsg.email} />}
                  {errors.email?.type === "pattern" && <ErrorText message={errMsg.email_pattern} />} */}
                </div>


                <div>
                  <InputText
                    className
                    lable="Domain*"
                    _id="InputDomain"
                    type="text"
                    // defaultValue={SiteName}
                    placeholder="www.abcdbloodbank@example.com"
                    isIcon={false}
                    name="domain"
                    onChange={formik.handleChange}
                    value={formik.values.domain}
                  // onChangeText={(action, value) => setdomainName(value)}
                  // value={domainName}
                  // validationRef={register({
                  //   required: true,
                  //   pattern: validText
                  // })}
                  />
                  {/* {errors.lastName?.type === "required" && <ErrorText message={errMsg.domainName} />}
                  {errors.domainName?.type === "pattern" && <ErrorText message={errMsg.textOnly} />} */}
                </div>

                {/* <div>
                  <InputText
                    className
                    lable="Status"
                    _id="InputStatus"
                    type="text"
                    defaultValue={Active}
                    placeholder="Status"
                    isIcon={false}
                    name="SiteName"
                  onChangeText={(action, value) => setdomainName(value)}
                  value={domainName}
                  validationRef={register({
                    required: true,
                    pattern: validText
                  })}
                  />
                  {errors.lastName?.type === "required" && <ErrorText message={errMsg.domainName} />}
                  {errors.domainName?.type === "pattern" && <ErrorText message={errMsg.textOnly} />}
                </div> */}

                <div>
                  <div className="row flex-column d-block w-100 mx-0 select-cover">
                    <SelectBox
                      lable="Status*"
                      isIcon={false}
                      _id="InputStatus"
                      data={statusOptData}
                      name="status"
                      onChange={formik.handleChange}
                      value={formik.values.status}
                    // onChangeText={(action, value) => setstateArea(value)}
                    // value={stateArea}
                    // validationRef={register({
                    //   required: true
                    // })} 
                    />
                    {/* {errors.stateArea && <ErrorText message={errMsg.state} />} */}
                  </div>
                </div>



                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="row flex-column">
                        <InputText
                          lable="Address*"
                          _id="InputAddress"
                          type="text"
                          placeholder="Address"
                          name="addressLine1"
                          onChange={formik.handleChange}
                          value={formik.values.addressLine1}
                        // onChangeText={(action, value) => setaddressLine1(value)}
                        // value={addressLine1}
                        // validationRef={register({
                        //   required: true
                        // })}
                        />
                        {/* {errors.addressLine1 && <ErrorText message={errMsg.addressLine1} />} */}
                      </div>
                    </div>



                    <div className="col-lg-12 col-12">
                      <div className="row flex-column">
                        <InputText
                          lable="Address (Optional)"
                          _id="InputAddressOptional"
                          type="text"
                          placeholder="Address (Optional)"
                          name="addressLine2"
                          onChange={formik.handleChange}
                          value={formik.values.addressLine2}
                        // onChangeText={(action, value) => setaddressLine2(value)}
                        // value={addressLine2}
                        // validationRef={register({
                        //   required: false
                        // })}
                        />
                      </div>
                    </div>

                    <div className="col-12 px-0">
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12 pr-md-1 pr-sm-1">
                          <div className="row flex-column d-block w-100 mx-0">
                            <InputText
                              lable="City*"
                              _id="InputCityName"
                              type="text"
                              placeholder="City Name"
                              isIcon={false}
                              name="city"
                              onChange={formik.handleChange}
                              value={formik.values.city}
                            // onChangeText={(action, value) => setcity(value)}
                            // value={city}
                            // validationRef={
                            //   register({
                            //     required: true,
                            //     pattern: validText
                            //   })}
                            />
                            {/* {errors.city?.type === "required" && <ErrorText message={errMsg.city} />}
                            {errors.city?.type === "pattern" && <ErrorText message={errMsg.textOnly} />} */}
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12 px-lg-2 pl-md-1 pl-sm-1">
                          <div className="row flex-column d-block w-100 mx-0 select-cover">
                            <SelectBox
                              lable="State*"
                              isIcon={false}
                              _id="inputGroupSelectStateArea"
                              data={stateOptData}
                              name="stateArea"
                              onChange={formik.handleChange}
                              value={formik.values.stateArea}
                            // onChangeText={(action, value) => setstateArea(value)}
                            // value={stateArea}
                            // validationRef={register({
                            //   required: true
                            // })} 
                            />
                            {/* {errors.stateArea && <ErrorText message={errMsg.state} />} */}
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-12 col-12 pl-lg-2">
                          <div className="row flex-column d-block w-100 mx-0">
                            <InputText
                              lable="Zip*"
                              _id="InputZipCode"
                              type="text"
                              placeholder="Zip Code"
                              isIcon={false}
                              name="zipcode"
                              maxLength={5}
                              onChange={formik.handleChange}
                              value={formik.values.zipcode}
                            // onChangeText={(action, value) => { setzipcode(value) }}
                            // value={zipcode}
                            // validationRef={register({
                            //   required: true,
                            //   pattern: /^\d+$/
                            // })}
                            />
                            {/* {errors.zipcode?.type === "required" && <ErrorText message={errMsg.zipcode} />}
                            {errors.zipcode?.type === "pattern" && <ErrorText message={errMsg.zipcode_pattern} />} */}
                          </div>
                        </div>


                      </div>




                    </div>
                  </div>
                </div>

              </div>

              <div className="col-12 col-md-6 col-lg-5 col-xl-6">
                <div className="row">
                  <div className="col-12">
                    <h6 className="text-danger">Select Modules</h6>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="card py-3">
                      {
                        // [
                        //   {name:'Site Management',id:'sm1',val:true},
                        //   {name:'Users',id:'sm2',val:false},
                        //   {name:'Role',id:'sm3',val:false},
                        //   {name:'Donor Management',id:'sm4',val:false},
                        //   {name:'CMS',id:'sm5',val:false},
                        //   {name:'Reports',id:'sm6',val:false}

                        // ]
                        siteEnablementData.map((val, ind) => {
                          return (
                            <div key={`encb${ind}`}>
                              <div className="custom-control custom-checkbox mx-0 mt-2 mb-3 border-bottom pb-4 pl-10">
                                <input
                                  type="checkbox"
                                  name={"enablement"}
                                  className="custom-control-input"
                                  // checked={formik.values.enablement}
                                  onChange={formik.handleChange}
                                  disabled={val.isOnlyForParentAdmin}
                                  id={val.id} />
                                <label className="custom-control-label" for={val.id} >{val.name}</label>
                              </div>
                            </div>
                          )
                        })
                      }
                      <h1>Fahad</h1>

                      {siteEnablementData && siteEnablementData.map((val, ind) => <Checkbox name="enablement" value={val?.id} />)}


                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div className="row">
              <div className="col-12 pt-10 text-md-left text-center">
                <button className="border bg-transparent p-3 text-secondary rounded-10 mr-2 px-12">Cancel</button>
                <button className="cta-bg border-0 p-3 text-white rounded-10 ml-2 px-12">Save</button>
              </div>
            </div>
          </form>


        )}





      </Formik>

      {/* <div className="row">
        <div className="col-12 pt-10 text-md-left text-center">
          <button className="border bg-transparent p-3 text-secondary rounded-10 mr-2 px-12">Cancel</button>
          <button className="cta-bg border-0 p-3 text-white rounded-10 ml-2 px-12">Save</button>
        </div>
      </div> */}
    </div>
  );
}

// const ContentHeading = ({title})=>{
//   return(
//     <div className="row">
//         <div className="col-12">
//           <h1>{title}</h1>
//         </div>  
//       </div>
//   )
// }


function Checkbox(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <label>
          {/* <input
            type="checkbox"
            {...props}
            checked={field.value.includes(props.value)}
            onChange={() => {
              if (field.value.includes(props.value)) {
                const nextValue = field.value.filter(
                  value => value !== props.value
                );
                form.setFieldValue(props.name, nextValue);
              } else {
                const nextValue = field.value.concat(props.value);
                form.setFieldValue(props.name, nextValue);
              }
            }}
          />
          {props.value} */}
        </label>
      )}
    </Field>
  );
}