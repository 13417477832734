import React, { useMemo, useEffect, useState, Fragment } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { actions} from "../../../../app/modules/Auth/_redux/authRedux";
import { useSelector, useDispatch } from "react-redux";
import { allSite } from "../../../../app/helper/apiDefinations";


var apiCall = "allSite";

export function Topbar() {
  const { user, authToken } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch(); 
  const uiService = useHtmlClassService();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); 
  const [allSiteData, setAllSiteData] = useState([]);

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  const onChangeClient = (val) =>{
    var selectedClientId = val.target.value;
    dispatch(actions.setUser({...user,selectedClientId:selectedClientId}));  
  }

  // api-calling-for-get-all-stie
  const callAllSite = async () => {
    apiCall = "allSite";
    let args = {
        pagination: {
            "pageNumber": 0,
            "pageSize": 0
        }, clientId: user?.clientId, token: authToken
    };
    try {
        setLoading(true);
        setErrorMessage(null);
        const res = await allSite(args);

        if (res.data?.errors) {
            setErrorMessage(res.data.errors[0].message);
        }

        if (res.data?.data?.allSite) {
            const siteData = res.data?.data?.allSite
            setAllSiteData(siteData);
        }

    } catch (error) {
        alert(error.message);
    } finally {
        setLoading(false);
    } 
  }


  useEffect(() => {
    callAllSite()
  }, []);

  return (
    <>
      <div className="col-12 px-0">
        <div className="row align-items-center h-100">
          <div className="col siteSwitchCover">
           
            { user.clientId == 0 ? 
            <div className="siteSwitch h-100">
              <div class="col-12 h-100">
                <div className="row flex-row align-items-center my-3 h-100">
                  <span className="text-muted font-size-sm"><a className="text-secondary" href="#">Switch Client</a></span>
                  <span> 
                    <select onChange={onChangeClient} name="state" label="State" className="form-control ml-3 custom-select shadow-sm custom-select-sm rounded-10">
                        { user.selectedClientId == 0 ? <option value="0">Degree37</option> : <option selected value="0">Degree37</option> }  
                      { allSiteData?.Site !== undefined && allSiteData?.Site.sort((a, b) => a.siteId > b.siteId ? 1 : -1).map(element => {  
                          return user.selectedClientId == element.siteId ? <option selected value={element.siteId}>{element.name}</option>   : <option value={element.siteId}>{element.name}</option>   
                        }) 
                      } 
                      
                    </select> 
                  </span>
                   
                    
                             
                </div>
              </div>
            </div>
            :
            <div className="siteSwitch h-100"></div>
          }   
          </div>
          <div className="topbar">
            {/* {layoutProps.viewSearchDisplay && <SearchDropdown />} */}

            {/* {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />} */}

            {/* {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />} */}

            {/* {layoutProps.viewCartDisplay && <MyCartDropdown />} */}

            {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}

            {layoutProps.viewUserDisplay && <QuickUserToggler />}
          </div>
        </div>
      </div>
    </>
  );
}
