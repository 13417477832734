import moment from 'moment';
import { addActivityLogsForLoginFails, addActivityLogs, addAdminDonationScheduledLogs } from "./apiDefinations";
import { browserName } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";

// for curency
export function numberWithCommas(x) {
  if (x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
    return x;
  }
}

// export function numberWithDash(x) {
//     if (x) {
//         x = x.toString();
//         // var pattern = /(-?\d+)(\d{4})/;
//         var pattern = /^((?:\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/;
//         while (pattern.test(x))
//             x = x.replace(pattern,"$1,$2,$3");
//         return x;
//     }
// }

// for phone number
export function numberWithDash(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return phoneNumberString
}

export function dateFormater(date, formate='DD MMM YYYY') {
  const formatedDate = moment(date).format(formate);
  return formatedDate === "Invalid date" ?
    '--' :
    formatedDate;
}

export function UTCtoDate(utc, formate) { 
  const date = new Date(+utc); 
  const time = moment(date).format(formate); 
  return time;
} 

export const pageSize = 10; 

const publicIp = require('public-ip');
const ipLocation = require("iplocation");
export const getDonorLocationInfo  = async () => {
    var ipAddress = null;
    var location = null 
    ipAddress = await publicIp.v4();
    if(ipAddress){
        location = await ipLocation(ipAddress);
        if(location){
            return {
                country: location.country.name,
                state: location.region.name,
                ip: ipAddress 
            }
        } 
    } 
}

export const createActivityLogsForLoginFails = async ({Email, pageName, activity, error }) => {
  try {
      var location = await getDonorLocationInfo();
      if(location){ 
          let activityData = { 
            clientId: 0,
            variables:{
                email: Email,
                pageName: pageName,
                activity: activity,
                application: 'Web-' + browserName,
                userRole: "superUser",
                userAgent: navigator.userAgent,
                location: {
                    ip: location.ip,
                    state: location.state,
                    country: location.country
                },
                error: error
            }
          }

          const res = await addActivityLogsForLoginFails( activityData );
          if (res.status === 200) {
              if (res?.data?.data.createActivityLogs) {  
              }
              if (res?.data?.errors) { 
                  // const errors = res.data.errors;
                  // Swal.fire('', `${errors.map(val => val.message)}`, 'error');
              }
          }
      } 
  } catch (error) {

  } finally { 
  }
}

// const { user, authToken } = useSelector(({ auth }) => auth);
export const createActivityLogs = async ({Email, pageName, activity, error, user, authToken }) => {
  try {
    // console.log("user", user, "authToken", authToken)
      var location = await getDonorLocationInfo();
      if(location){ 
          let activityData = { 
            clientId: user.clientId,
            token: authToken, 
            variables:{
                email: Email,
                pageName: pageName,
                activity: activity,
                application: 'Web-' + browserName,
                userRole: "superUser",
                userAgent: navigator.userAgent,
                location: {
                    ip: location.ip,
                    state: location.state,
                    country: location.country
                },
                error: error
            }
          }

          const res = await addActivityLogs ( activityData );
          if (res.status === 200) {
              if (res?.data?.data.createActivityLogs) {  
              }
              if (res?.data?.errors) { 
                  // const errors = res.data.errors;
                  // Swal.fire('', `${errors.map(val => val.message)}`, 'error');
              }
          }
      } 
  } catch (error) {

  } finally { 
  }
}

export const createDonationActivityLogs = async ({ pageName, activity, error, user, authToken, donor }) => {
  try {
    // console.log("user", user, "authToken", authToken)
      var location = await getDonorLocationInfo();
      if(location){ 
          let activityData = { 
            clientId: user.clientId,
            token: authToken, 
            variables:{ 
                pageName: pageName,
                activity: activity,
                application: 'Web-' + browserName, 
                userAgent: navigator.userAgent,
                location: {
                    ip: location.ip,
                    state: location.state,
                    country: location.country
                },
                donor: donor,
                error: error
            }
          }

          const res = await addAdminDonationScheduledLogs ( activityData );
          if (res.status === 200) {
              if (res?.data?.data.createAdminDonationScheduledLogs) { 
                  console.log("res?.data?.data.createAdminDonationScheduledLogs", res?.data?.data.createAdminDonationScheduledLogs) 
              }
              if (res?.data?.errors) { 
                  // const errors = res.data.errors;
                  // Swal.fire('', `${errors.map(val => val.message)}`, 'error');
              }
          }
      } 
  } catch (error) {

  } finally { 
  }
}

export const PageName = {
  Dashboard: 'dashboard',
  Login:  'auth/login',
  
  ClientManagementList: 'clientManagement/view',
  ClientManagementAdd: 'clientManagement/add',
  ClientManagementEdit: 'clientManagement/edit',
  
  ContentManagementAdsList: 'contentManagement/Ads/all',
  ContentManagementAdsEdit: 'contentManagement/Ads/edit',
  ContentManagementAdsAdd: 'contentManagement/Ads/add',
  ContentManagementAdsView: 'contentManagement/Ads/view',

  ContentManagementEmailtemplateList: 'contentManagement/emailtemplate/all',
  ContentManagementEmailtemplateAdd: 'contentManagement/EmailTemplates/add',
  ContentManagementEmailtemplateEdit: 'contentManagement/EmailTemplates/edit',
  ContentManagementEmailtemplateView: 'contentManagement/EmailTemplates/view',
  
  RolesManagementList: 'rolesManagement/view',
  RolesManagementAdd: 'rolesManagement/add',
  RolesManagementEdit: 'rolesManagement/edit',
  RolesManagementDetail: 'rolesManagement/detail',  
  
  UserManagementList: 'userManagement/all',
  UserManagementAdd: 'userManagement/add',
  UserManagementEdit: 'userManagement/edit',
  ResetPassword: 'userManagement/reset-password',
  UserManagementView: 'userManagement/view',
  UserManagementUserProfile: 'userManagement/user-profile',
  
  DonorManagementList: 'donorManagement/all',
  DonorManagementAdd: 'donorManagement/add',
  DonorManagementDetails: 'donorManagement/view',

  ReportAdminLogin: 'reports/adminLogin',
  ReportAdminActivity: 'reports/adminActivity',
  ReportDonorLoginFail: 'reports/donorLoginFail',
  ReportDonationsScheduled: 'reports/donationsScheduled',
  ReportDonorActivity: 'reports/donorActivity',
};

export const Activity = {
  Visit: 'Visit',
  Login: 'Login',
  
  ClientSearch: 'Search Client with "{0}" value.',
  ClientChangeStatus: 'Change Status of Client "{0}" with "{1}" mode.',
  ClientUpdate: 'Update Client "{0}".',
  ClientAdd: 'Create new Client "{0}".',

  AdsSearch: 'Search Ads with "{0}" value.',
  AdsDelete: 'Delete "{0}" Ads.',
  AdsUpdate: 'Update Ads "{0}".',
  AdsAdd: 'Create new Ads "{0}".',

  EmailtemplateSearch: 'Search Email Template with "{0}" value.',
  EmailtemplateDelete: 'Delete "{0}" Email Template.',
  EmailtemplateAdd: 'Create new Email Template "{0}".',
  EmailtemplateUpdate: 'Update Email Template "{0}".',

  DonorSearch: 'Filter Donor with {0} values.', 
  DonorAdd: 'Create new Donor with "{0}" email.', 

  RoleManagementSearch: 'Search Role with "{0}" value.',
  RoleManagementDelete: 'Delete "{0}" Role.',
  RoleManagementAdd: 'Create new Role "{0}".',
  RoleManagementUpdate: 'Update Role "{0}".',

  UserManagementSearch: 'Search Admin User with "{0}" value.',
  UserManagementChangePassword: 'Change Password of "{0}" Admin User.',
  UserManagementAdd: 'Create new Admin User with "{0}" email.',
  UserManagementUpdate: 'Update Admin User "{0}".',

  AddAppointment: 'Add Appointment For Donor',
  ModifyAppointment: 'Modify Appointment For Donor',
  CancelAppointment: 'Cancel Appointment For Donor',  
}

// email-secure
export const SecureEmail = (email) => {    
  var tempEmail = email.split("@");
  var firstChar = tempEmail[0].charAt(0);
  var lastChar = tempEmail[0].length > 1 ? tempEmail[0].charAt(tempEmail[0].length - 1) : '';
  return firstChar + "..." + lastChar + "@" +tempEmail[1];
}
export function DateUTCtoPST(timeStamp, format){
  const date = moment.tz(new Date(+timeStamp), "America/Los_Angeles").format(format);
  return date;
}
// utc convert to time
export function UTCtoTime(utc, isAmPm) {
  const date = new Date(+utc);
  if (isAmPm) {
      const hours = +moment(date).format('HH');
      const min = +moment(date).format('mm');
      const newTime = (hours * 60) + min;
      return newTime
  } else {
      const time = moment(date).format('hh:mm a');
      return time;
  }
}

export const timeSorting = (unSortTime) => { 
  let _sortedTime = [];
  unSortTime.map(val => {
      _sortedTime.push(
          { ...val, newTime: val.startTimeUTC }
      );
  })
  const Stime = _sortedTime.sort((a, b) => a.newTime - b.newTime);
  // const Stime = _sortedTime;
  return Stime;
}

export function UTCtoDateTime(utc, format) {
  const date = new Date(+utc);
  const time = moment(date).format(format);
  return time; 
}
//Time set for UI
export function TimeUTCtoPST(timeStamp, format){
  const date = moment.tz(new Date(+timeStamp), "America/Los_Angeles").format(format);
  return date;
}