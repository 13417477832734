import axios from "axios";
import { BASE_URL, deviceId, GQL_PUBLIC, userAgent } from "../../../helper/webServices";
import { Mutation_AdminSignIn, Operation_AdminSignIn } from "../../../helper/queries";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export async function loginApi(data) {
  const req = await axios({
    url: `${BASE_URL}/${GQL_PUBLIC}`,
    method: "POST",
    headers: {
      "User-Agent": `${userAgent}`,
      "client-id": `${'ccc'}`,
      "device-id": `${deviceId}`, 
    },
    data: {
      query: Mutation_AdminSignIn,
      variables: {
        user: {
          email: data.email,
          password: data.password 
        }
      },
      operationName: Operation_AdminSignIn,
    }
  });
  return req;
}


export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
