export const us_state = [
    {
        "id": "0",
        "name": "State",
        "code": ""
    },
    {
        "id": "1",
        "name": "Alabama",
        "code": "AL"
    },
    {
        "id": "2",
        "name": "Alaska",
        "code": "AK"
    },
    {
        "id": "3",
        "name": "Arizona",
        "code": "AZ"
    },
    {
        "id": "4",
        "name": "Arkansas",
        "code": "AR"
    },
    {
        "id": "5",
        "name": "California",
        "code": "CA"
    },
    {
        "id": "6",
        "name": "Colorado",
        "code": "CO"
    },
    {
        "id": "7",
        "name": "Connecticut",
        "code": "CT"
    },
    {
        "id": "8",
        "name": "Delaware",
        "code": "DE"
    },
    {
        "id": "9",
        "name": "Florida",
        "code": "FL"
    },
    {
        "id": "10",
        "name": "Georgia",
        "code": "GA"
    },
    {
        "id": "11",
        "name": "Hawaii",
        "code": "HI"
    },
    {
        "id": "12",
        "name": "Idaho",
        "code": "ID"
    },
    {
        "id": "13",
        "name": "Illinois",
        "code": "IL"
    },
    {
        "id": "14",
        "name": "Indiana",
        "code": "IN"
    },
    {
        "id": "15",
        "name": "Iowa",
        "code": "IA"
    },
    {
        "id": "16",
        "name": "Kansas",
        "code": "KS"
    },
    {
        "id": "17",
        "name": "Kentucky[E]",
        "code": "KY"
    },
    {
        "id": "18",
        "name": "Louisiana",
        "code": "LA"
    },
    {
        "id": "19",
        "name": "Maine",
        "code": "ME"
    },
    {
        "id": "20",
        "name": "Maryland",
        "code": "MD"
    },
    {
        "id": "21",
        "name": "Massachusetts[E]",
        "code": "MA"
    },
    {
        "id": "22",
        "name": "Michigan",
        "code": "MI"
    },
    {
        "id": "23",
        "name": "Minnesota",
        "code": "MN"
    },
    {
        "id": "24",
        "name": "Mississippi",
        "code": "MS"
    },
    {
        "id": "25",
        "name": "Missouri",
        "code": "MO"
    },
    {
        "id": "26",
        "name": "Montana",
        "code": "MT"
    },
    {
        "id": "27",
        "name": "Nebraska",
        "code": "NE"
    },
    {
        "id": "28",
        "name": "Nevada",
        "code": "NV"
    },
    {
        "id": "29",
        "name": "New Hampshire",
        "code": "NH"
    },
    {
        "id": "30",
        "name": "New Jersey",
        "code": "NJ"
    },
    {
        "id": "31",
        "name": "New Mexico",
        "code": "NM"
    },
    {
        "id": "32",
        "name": "New York",
        "code": "NY"
    },
    {
        "id": "33",
        "name": "North Carolina",
        "code": "NC"
    },
    {
        "id": "34",
        "name": "North Dakota",
        "code": "ND"
    },
    {
        "id": "35",
        "name": "Ohio",
        "code": "OH"
    },
    {
        "id": "36",
        "name": "Oklahoma",
        "code": "OK"
    },
    {
        "id": "37",
        "name": "Oregon",
        "code": "OR"
    },
    {
        "id": "38",
        "name": "Pennsylvania[E]",
        "code": "PA"
    },
    {
        "id": "39",
        "name": "Rhode Island[F]",
        "code": "RI"
    },
    {
        "id": "40",
        "name": "South Carolina",
        "code": "SC"
    },
    {
        "id": "41",
        "name": "South Dakota",
        "code": "SD"
    },
    {
        "id": "42",
        "name": "Tennessee",
        "code": "TN"
    },
    {
        "id": "43",
        "name": "Texas",
        "code": "TX"
    },
    {
        "id": "44",
        "name": "Utah",
        "code": "UT"
    },
    {
        "id": "45",
        "name": "Vermont",
        "code": "VT"
    },
    {
        "id": "46",
        "name": "Virginia[E]",
        "code": "VA"
    },
    {
        "id": "47",
        "name": "Washington",
        "code": "WA"
    },
    {
        "id": "48",
        "name": "West Virginia",
        "code": "WV"
    },
    {
        "id": "49",
        "name": "Wisconsin",
        "code": "WI"
    },
    {
        "id": "50",
        "name": "Wyoming",
        "code": "WY"
    }
]

export const genderOptData = [
    { code: '', name: 'Select' },
    { code: 'M', name: 'Male' },
    { code: 'F', name: 'Female' }
]

export const stateOptData = (us_state);