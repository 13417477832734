import React, { useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { loginApi } from "../_redux/authCrud";
import { userAdminRold, GetOTPForResetPassword, ResetPasswordByOTP } from "../../../helper/apiDefinations"
import { createActivityLogsForLoginFails, createActivityLogs, PageName, Activity } from "../../../helper/utils";
import Swal from 'sweetalert2';
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "", 
  password: "", 
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [forgetPassLoading, setForgetPassLoading] = useState(false);
  
  const [errorMessage, setErrorMessage] = useState(null);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 cheracters")
      .max(50, "Maximum 50 cheracters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      // .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 cheracters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const onForgetPass = async () => {
    const { value: email } = await Swal.fire({
        title: 'Forget Password',
        input: 'email',
        inputLabel: 'Enter your email address',
        inputPlaceholder: 'Enter your email address',
        confirmButtonColor: '#FF8C00',
        showLoaderOnConfirm: true,
        showCloseButton: true,
    });
    if (email) {
        try {
          setForgetPassLoading(true);
          const forgetReq = await onForgetPasswordReq(email);
          const errors = forgetReq?.data?.errors;
          const res = forgetReq?.data?.data?.createOTPForResetPasswordForAdminUser;
          if (errors) {
              Swal.fire(`Message`, `${errors.map(v => v.message)}`, 'error');
          }
          if (res) {
              Swal.fire(`Message`, 'Please check email.', 'success');
          }
        } catch (error) {

        } finally {
          setForgetPassLoading(false);
        }
    }

  }


  const onForgetPasswordReq = async (data) => {
    try {
      
      var tempData = {
        clientId:0,
        variables: {
          email:data
        }
      }
      
      const res = await GetOTPForResetPassword(tempData);
      return res;
    } catch (error) {
    } finally {
        // setLoading(false)
    }
  }



  

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  // api-calling-for-SIDE MENU
  const callUserAdminRole = async (userRolrId, clientId, authToken) => { 
    let variables = {
      id: userRolrId 
    }
    let args = {variables, clientId: clientId, token: authToken };
    try { 
      const res = await userAdminRold(args); 
      props.SetMenu(res.data.data.userAdminRoleById.enablement)
    } catch (error) {
    } finally {
      // setLoader_count(false);
    }
  } 

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      try { 
        const res = await loginApi(values);
        if (res.data.data?.adminSignIn) {
          props.login(res);
          // console.log(res)
          callUserAdminRole(res.data.data.adminSignIn.adminRole_Id, res.data.data.adminSignIn.clientId, res.headers.token);
          createActivityLogs({Email: values.email, pageName: PageName.Login, activity: Activity.Login, error: null, user: res.data.data.adminSignIn, authToken: res.headers.token });
        } else {
          
          setSubmitting(false);
          setStatus(
            res.data.errors[0].message
          ); 
          // createActivityLogsForLoginFails({Email:values.email, pageName: PageName.Login, activity: Activity.Login, error:res.data.errors[0].message});
        }
      } catch (error) { 
        setSubmitting(false);
        setStatus(
          
          error.message
        );
      } finally {
        disableLoading();

      }

      disableLoading();
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            value="test@cc.com"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            value="1Apptest!"
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            // to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >            
            {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
            <div class="d-flex-inline">
                <span className="btn btn-link bg-tranparent border-0"
                onClick={onForgetPass}
                >
                Forget Password
                </span>
            </div>
          </Link>

          {/* reset password button */}

          {/* <Link
            to="/auth/reset-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
            >
            Reset Password            
          </Link> */}

          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
