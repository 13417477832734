import React from 'react';

// content main parent
export const ParentContent = ({ children }) => (
    <div className="row">
        <div className="col-xxl-12 order-2 order-xxl-1">
            {children}
        </div>
    </div>
);
// content area table
export const ContentArea = ({ className, children }) => (
    <div className="row mx-0">
        <div className="col-12 px-0">
            <div className={className ? className + " table-responsive overflow-handle" : "table-responsive overflow-handle"}>
                {children}
            </div>
        </div>
    </div>
);

// Row heading
export const ContentHeadingRow = ({ title, onButtonClick, buttonTitle, isVisable }) => (
    <div className="row align-items-center justify-content-between mx-0">
        <div className="col-12 px-0">
            <div className="row align-items-center justify-content-between mx-0 pt-3 pt-lg-0">
                <h1 className="">{title}</h1>
                {
                    (buttonTitle && onButtonClick && isVisable)
                    && <button onClick={onButtonClick}
                        className="font-weight-bold border-0 bg-transparent rounded-10 ml-lg-2 mr-lg-0 cta-bg mb-2 p-3 px-lg-5 text-white">
                        {buttonTitle}
                    </button>
                }
            </div>
        </div>
    </div>
);

// select Date Range
export const SelectDateRange = ({ children }) => (
    <div className="col-12 my-3 px-0 pb-3">
        <div className="row mx-0 dateRangeCover">
            {/* {children} */}
            <div className="col-md-12 col-sm-12 my-0 px-0 pb-0">
                <div className="row mx-lg-0 mx-auto align-items-end justify-content-md-between">
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export const DownloadReportsBtn = ({children}) => (
    <div className="col-12 my-3 px-0 pb-3">
        <div className="downloadBtns">
            {children}
        </div>
    </div>
);

// search and pagination row
export const SearchAndPaginationCover = ({ children }) => (
    <div className="col-12 my-3 px-0 pb-3">
        <div className="row mx-0">
            {children}
        </div>
    </div>
);

// search component
export const DegreeSearch = ({ onKeyUp, onClick, onChange, value }) => (
    <div className="col-8 px-0">
        <div className="row align-items-start mx-0 px-0">
            <div className="search-cover col px-0">
                <div className="input-group add-on">
                    <input className="form-control"
                        placeholder="Search"
                        name="srch-term"
                        id="srch-term"
                        value={value}
                        onChange={onChange}
                        onKeyUp={onKeyUp}
                        type="search" />
                    <div className="input-group-btn">
                        <button className="btn btn-default bg-transparent border border-left-0" onClick={onClick}  >
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
