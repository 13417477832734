import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
    // GetDonorLoginFailReport
} from "../../../helper/apiDefinations";
import { createActivityLogs, PageName, Activity, pageSize, dateFormater } from "../../../helper/utils";
import {
    ParentContent,
    ContentHeadingRow,
    SearchAndPaginationCover,
    SelectDateRange,
    DegreeSearch,
    ContentArea,
    DownloadReportsBtn
} from "../../../../_metronic/layout/components/degree/components/common";
import BootstrapTable from "react-bootstrap-table-next";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Loader } from "../../../../_metronic/layout/components/Layout";
import { Input, Select, DatePickerField } from "../../../../_metronic/_partials/controls";
import Iframe from 'react-iframe'



export default function marketingAutomation() {
    
    // const history = useHistory();    

    // const { user, authToken } = useSelector(({ auth }) => auth);
    // const [errorMessage, setErrorMessage] = useState(null);
    // const [loading, setLoading] = useState(false);
    // const [isActivityLogCall, setIsActivityLogCall] = useState(false);

    // useEffect(() => {
    //     if (!isActivityLogCall && user) {
    //         createActivityLogs({ Email: user.email, pageName: PageName.ReportDonorLoginFail, activity: Activity.Visit, error: null, user: user, authToken: authToken });
    //         setIsActivityLogCall(true);
    //     }
    // }, []);



    // const enableLoading = () => {
    //     setLoading(true);
    // };

    // const disableLoading = () => {
    //     setLoading(false);
    // };

    

    return (
        <Fragment>
            {/* {
                loading &&
                <Loader loader={loading} />
            } */}
            <ParentContent>

                <ContentHeadingRow
                    title="Marketing Automation"
                />                

                <div class="d-flex flex-column col-md-3 col-sm-4 col-12 pl-0 pt-4">
                <a
                    href="https://bloodworks.degree37.io/"
                    target="_blank"
                    className="rounded-10 cta-bg mb-3 font-18 font-weight-bold text-white px-lg-4 px-md-3 px-2 border-0 btn btn-primary">
                    AutomationTool
                </a>
                <a
                    href="https://bloodworks.degree37.io/Campaign"
                    target="_blank"
                    className="rounded-10 cta-bg mb-3 font-18 font-weight-bold text-white px-lg-4 px-md-3 px-2 border-0 btn btn-primary">
                    Campaigns
                </a>
                <a
                    href="https://bloodworks.degree37.io/Contact/Search"
                    target="_blank"
                    className="rounded-10 cta-bg mb-3 font-18 font-weight-bold text-white px-lg-4 px-md-3 px-2 border-0 btn btn-primary">
                    Contacts
                </a>
                <a
                    href="https://bloodworks.degree37.io/Gallery"
                    target="_blank"
                    className="rounded-10 cta-bg mb-3 font-18 font-weight-bold text-white px-lg-4 px-md-3 px-2 border-0 btn btn-primary">
                    Content
                </a>
                <a
                    href="https://bloodworks.degree37.io/Segment"
                    target="_blank"
                    className="rounded-10 cta-bg mb-3 font-18 font-weight-bold text-white px-lg-4 px-md-3 px-2 border-0 btn btn-primary">
                    Segments
                </a>
                </div>

            </ParentContent>
        </Fragment>
    );
}


