/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const { user } = useSelector(({ auth }) => auth);
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {/* TODO:: dropdown only for super-admin */}
            {/* {user.clientId === "0" &&
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                    <a className="menu-link">
                        <span className="menu-text">Dononr List</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        </a>
                </li>} */}
            {/*end::1 Level*/}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
