import React from 'react';
import moment from 'moment';

import './inputText.css';


export const InputText = ({
    lable,
    _id,
    type,
    placeholder,
    isIcon,
    onChangeText,
    onChange,
    value,
    style,
    isValueShow,
    validationRef,
    name,
    defaultValue,
    onClickIcon,
    onKeyPress,
    maxLength,
    disabled
}) => {

    return (
        <div style={style}>
            <div className="input-text mb-3">
                {lable && <label htmlFor={_id}>{lable}</label>}
                <div className="d-flex justify-content-between">
                    <input
                        type={isValueShow ? 'text' : type}
                        id={_id}
                        autoComplete="on"
                        placeholder={placeholder}
                        onKeyPress={onKeyPress}
                        ref={validationRef}
                        name={name}
                        defaultValue={defaultValue}
                        // pattern={name === "zipcode" && "\d*"}
                        maxLength={maxLength}
                        disabled={disabled}
                        onChange={onChange}
                    // value={value}
                    />
                    {/* {isIcon && <img width="100" height="50" src={require('../../assets/logo.png')} />} */}
                    {isIcon && <span className="is-icon"
                        onClick={onClickIcon}>***</span>}
                </div>
            </div>
        </div>
    );
}

export const SelectBox = ({
    lable,
    onChangeText,
    value,
    _id,
    onChange,
    isIcon,
    style,
    validationRef,
    data,
    name
}) => {
    return (
        <div style={style}>
            <div className="input-text mb-lg-1 mb-2">
                <label htmlFor={_id}>{lable}</label>
                <div className="d-flex justify-content-between">
                    <select
                        id={_id}
                        ref={validationRef}
                        name={name}
                        // value={value}
                        onChange={onChange}                        
                    >
                        {data && data.map((val, ind) => {
                            return <option
                                hidden={ind === 0 && true}
                                key={ind}
                                value={val.code}>{`${val.name}`}</option>
                        }
                        )}

                        {/*
                        <option value="2">New York</option>
                        <option value="3">Three</option> */}
                    </select>
                    {/* {isIcon && <img width="100" height="50" src={require('../../assets/logo.png')} />} */}
                    {isIcon && <span className="is-icon">***</span>}
                </div>
            </div>
        </div>

    )
}

export const TextArea = ({
    lable,
    onChangeText,
    value,
    _id,

    isIcon,
    style,
    validationRef,
    data,
    name
}) => {
    return (
        <div style={style}>
            <div className="input-text mb-lg-1 mb-2">
                <label htmlFor={_id}>{lable}</label>
                <div className="d-flex justify-content-between">
                    <textarea
                        id={_id}
                        ref={validationRef}
                        name={name}
                        placeholder="1221 W 3rd ST APT 139"
                        className="textarea w-100 border-0"
                    // value={value}
                    // onChange={e => onChangeText(name, e.target.value)}
                    >

                        {data && data.map((val, ind) => {
                            return <option key={ind} value={val.code}>{val.name}</option>
                        }
                        )}

                        {/*
                        <option value="2">New York</option>
                        <option value="3">Three</option> */}
                    </textarea>
                    {/* {isIcon && <img width="100" height="50" src={require('../../assets/logo.png')} />} */}
                    {isIcon && <span className="is-icon">***</span>}
                </div>
            </div>
        </div>

    )
}



export const DateBox = ({
    lable,
    validationRef,
    _id,
    type,
    placeholder,
    isIcon,
    style,
    name,
    onChangeText,
    value,
    maxDate,
    minDate
}) => {
    return (
        <div style={style}>
            <div className="input-text mb-3">
                <label htmlFor={_id}>{lable}</label>
                <div className="d-flex justify-content-between">
                    <input type={type}
                        id={_id}
                        aria-describedby="emailHelp"
                        placeholder={placeholder}
                        onfocus="(this.type='date')"
                        onblur="if(this.value==''){this.type='text'}"
                        ref={validationRef}
                        name={name}
                        onChange={e => onChangeText(name, e.target.value)}
                        data-date-format="DD MMMM YYYY"
                        max={maxDate}
                        min={minDate}
                        className="datepicker-input"
                    />
                    {/* {isIcon && <img width="100" height="50" src={require('../../assets/logo.png')} />} */}
                    {isIcon && <span className="is-icon">***</span>}
                </div>
            </div>
        </div>

    )
}